/* eslint-disable react/jsx-no-duplicate-props */
// @ts-nocheck
import React, { Component, Fragment } from "react";
import axios from "axios";
import {
  favouriteArtworks,
  favouriteExhibition,
  fetchCurateDetails,
  fetchV2Scenes,
  fetchExhibitionsDetails,
  fetchCurateLinkedArtworks, fetchCurateDetailsV2,
} from "../../services/api";
import CurateView from "../../components/CurateView";
import CurateHeader from "../../components/CurateHeader";
import CurateSidebarDark from "../../components/CurateSidebarDark";
import { NURBS_API_URL } from "../../components/Constants";
// import ArtworksAudioDetails from "../../components/ArtworksAudioDetails";
import Spinner from "../../components/Spinner";
import {
  constructImageUrl,
  constructVideoUrl,
  formatDate,
  getIdFromSlug,
  toInches,
  getShareURL,
  getArtworkStateStatus,
  isTypeMuseum,
  isMobileDevice, constructCFUrl, constructAudioUrl, constructHDRCFUrl
} from "../../utils";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import ConversationIcon from "../../components/Header/ConversationIcon";
import RoomLoader from "../../components/RoomLoader";
import states from "./states";
import logger from "../../utils/logger";
import CurateEnquireSideBar from "../../components/CurateEnquireSideBar";
import Audio from "../../components/Audio";
import Artwork2dCarousel from "../../components/Artwork2DCarousel";
import ArtworkCarousel from "../../components/ArtworkCarousel";
import rudderStackEvents from "../../services/rudderstack-events";
import AuthenticationForm from "../../components/AuthenticationForm";
import ListviewFooter from "../../components/ListviewFooter";
import IconBtn from "../../components/IconBtn";
import SidePanel from "../../components/SidePanel";
import CurateMobileArtwork from "../../components/CurateMobileArtwork";
import FadeDown from "../../components/FadeDown/FadeDown";
import SlideOut from "../../components/SlideOut/SlideOut";
import AuthModalForm from "../../components/AuthModalForm";
import Listview from "../../components/Listview";
import ExhibitionCurateLoading from "../../components/ExhibitionCurateLoading";
import RoomSwitchModal from "../../components/RoomSwitchModal";
import classNames from "classnames";
import {IS_DEVELOPMENT} from '../../components/Constants';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

// ? We will need this back, so just commenting it for now.
// const tooltips = {
//   desktop: ["Click and hold on an artwork for details."],
//   desktopDetailedMode: [
//     "Click on the artwork to zoom in. Click and drag to rotate.",
//   ],
//   mobile: ["Double tap an artwork to learn more."],
//   mobileDetailedMode: [
//     "Drag to rotate artwork in any direction.",
//     "Tap the right arrow to see the next artwork.",
//   ],
// };

const CURATE_LOAD_STATE = {
  ROOM_LOADED: 'ROOM_LOADED',
  ARTWORKS_FETCHED: 'ARTWORKS_FETCHED'
};

class Curate extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.artworkDetailsRef = React.createRef();
    this.enquireModalRef = React.createRef();
    this.authModalRef = React.createRef();
    this.exhibitionLoadingScreenRef = React.createRef();
    this.sceneResizeTimeoutRef = React.createRef();
    this.listviewTimeoutRef = React.createRef();
    this.exhibitionLoadingCompleteTimeoutRef = React.createRef();
    this.resizeAllTimeoutRef = React.createRef();
    this.toggleArtworkDetailsRef = React.createRef();
    this.eventsSidebar = React.createRef;
    this.timeInterval = null;
  }

  // artworkDetailsBox = null;
  state = states;

  // const { params } = this.props;
  // const { id } = params;


  setCurrentMobileArtworkState = (val) => {
    if (this.state.instructed) {
      this.setState({ currentMobileArtworkState: val });
    }
  };

  setToggleLogin = (val) => {
    this.setState({ toggleLogin: val });
  };

  setActiveTooltipTitle = (val) => {
    this.setState({ activeTooltipTitle: val });
  };

  setShowTootltip = (val) => {
    this.setState({ showTootltip: val });
  };

  setIsMobileArtworkExpanded = (val) => {
    if (this.state.instructed) {

      this.setState({ isMobileArtworkExpanded: val }, () => {
        if (val && !this.state.firstArtworkVisited) {
          this.setState({ firstArtworkVisited: true });
        }
      });
    }
  };

  getV2Scenes = async () => {
    let rooms = [];
    if(this.props.isPreview) {
      rooms = await fetchV2Scenes(
          this.props.params.id,
          this.props.isPreview
      ).then((resp) =>
          resp.data.map((room) => ({
            label: room.data.name,
            value: room.data.id,
            isArtistsVisible: room.data.isArtistsVisible,
            isLockedRoom: room.data.isLockedInDetailsMode,
          }))
      );
    }
    else {
        rooms = this.state.curateV2Response.scenes.map((room) => ({
            label: room.data.name,
            value: room.data.id,
            isArtistsVisible: room.data.isArtistsVisible,
            isLockedRoom: room.data.isLockedInDetailsMode,
        }));
    }

    const currentRoomId = this.state.selectedRoomId;
    this.setState({
      rooms,
    }, () => {
      const currentRoom = currentRoomId ? this.state.rooms.filter(room => room.value == currentRoomId)[0] : rooms[0];
      this.setState({
        selectedRoom: currentRoom,
        selectedRoomId: currentRoom && currentRoom.value,
        isLockedRoom: currentRoom && currentRoom.isLockedRoom === 1,
      })
    });
  };
  fetch3dViewOnFirstTime = () => {
    this.getData(this.state.selectedRoomId, true);
  };

  resizeAll = () => {
    this.getCurateHeight();
      this.setState({ viewportWidth: window.innerWidth }, () => {
        this.sceneResizeListener();
      });
      // TODO: ??
      // this.resizeLoop();
      // this.resizeLoop();
  }

  setArtworksListForListView = async (linkedArtworks, sceneId, eliminateSignedUrl) => {
    const artworks = await this.processArtworksArray(linkedArtworks, sceneId, eliminateSignedUrl !== "false")
    if(artworks) {
      this.setState({ artworksList: artworks })
    }
  }

  public async componentDidMount() {
    const { setContentType } = useTrackingCode();
    setContentType('exhibition-curate-page');
    const { location, queryParams } = this.props;
    // let { artworkId, listview, sceneId, eliminateSignedUrl } = this.getLocationSearchIds(
    //   location.search
    // );
    let { artworkId, listview, sceneId, eliminateSignedUrl } = queryParams;

    if(!this.props.isPreview) {
        const curateV2Response = await fetchCurateDetailsV2(this.props.params.id, sceneId || this.state.selectedRoomId, listview, true);
        this.setState({ curateV2Response: curateV2Response });
    }
    if (this.state.exhibitionDetails === null) {
      await this.loadExhibitionDetails();
    }
    this.setState({ listview }, async () => {
      if(listview){
        // Open Artwork Carousel For ListView If artworkId is in the URL
        if (artworkId) {
          this.setState({ isArtwork2dModal: true });
        }
        // Load All Artworks
        let linkedArtworks = {};
        if(this.props.isPreview) {
          linkedArtworks = await fetchCurateLinkedArtworks(
              this.props.params.id,
              (!this.state.listview && this.state.selectedRoomId) ?? sceneId,
              true,
              this.props.isPreview,
              eliminateSignedUrl !== "false"
          );
        }
        else {
          linkedArtworks['dimensions'] = this.state.curateV2Response.dimensions;
        }
        logger.log('linkedArtworks = ', linkedArtworks)
        this.setState({ detailedArtworks: linkedArtworks.dimensions.map((detail) => {
          return {
            data: {
              ...detail.data.artwork.data,
              artworkAudio: detail.data.channels[0]?.audio ? constructAudioUrl(detail.data.channels[0]?.audio) : "",
              isCollateral: detail.data.isCollateral,
              exhibitionSceneId: detail.data.exhibitionSceneId,
            }
          }
        })}, () => {
          this.setState({ listViewLoading: false });
          this.setArtworksListForListView(linkedArtworks, sceneId, eliminateSignedUrl);
        })

      } else {
        // It will run when ListView is false in URL!
        const urlParams = new URLSearchParams(location.search);
        const myParam = urlParams.get("sceneId");
        if (myParam) {
          console.log('myParam => ', myParam);
          this.setState({ audioDetailsPopup: false });
          // Load Data for the curate.
          this.getData(myParam, true);
        } else {
          // Load Data for the curate.
          console.log('myParam => not found', this.state.selectedRoomId);
          this.getData(this.state.selectedRoomId, true);
        }
      }
    })

    await this.getV2Scenes(this.props.params.id);

    if(this.props.location?.state?.details?.data || this.state.exhibitionDetails?.data){
      if (rudderStackEvents) {
        rudderStackEvents.mount({
          exhibitionId: this.props.location?.state?.details?.data?.id ?? this.state.exhibitionDetails?.data.id,
          exhibitionName: this.props.location?.state?.details?.data?.name ?? this.state.exhibitionDetails?.data.name,
          galleryId:
            this.props.location?.state?.details?.data?.organisation?.data?.id ?? this.state.exhibitionDetails?.data?.organisation.data.id,
          galleryName:
            this.props.location?.state?.details?.data?.organisation?.data?.name ?? this.state.exhibitionDetails?.data?.organisation.data.name,
          exhibitionUuid: this.props.location?.state?.details?.data?.uuid ?? this.state.exhibitionDetails?.data?.uuid,
        });
        rudderStackEvents.onExhibitionEnter();
      }
      this.setState({
        // isFavourite: this.props.location?.state?.details?.data?.isFavourite ?? this.state.exhibitionDetails?.data?.isFavourite,
        // loadingPageDetails: this.props.location?.state?.details?.data ?? this.state.exhibitionDetails?.data,
      });
    }

    if (document.getElementById("3d")) {
      document.querySelector("html").style.height = "100%";
      document.querySelector("body").style.height = "100%";
      document.getElementById("root").style.height = "100%";
      document.querySelector("#root > div")?.style?.height = "100%";
    } else {
      this.exitCurateView();
    }
    this.getCurateHeight();

    window.addEventListener("resize", () => {
      this.resizeAll();
      this.resizeAllTimeoutRef.current = setTimeout(() => {
        this.resizeAll();
      }, 1000)
    });

    window.addEventListener("orientationchange", this.handleOrientationChange);
  }

  handleDescription = () => {
    if (
      this.state.artworkDescHeight !== 0 &&
      this.state.isArtworkDescription === true
    ) {
      this.setState({ curateHeight: 1 });
    }
  };

  focusCanvas = () => {
    const curateWrapper = document.getElementById("3d");
    curateWrapper.querySelector("canvas")?.focus();
  };

  toggleDraggable = (val) => {
    if (val === true) {
      this.setState({ audioDetailsPopup: true, isAudioPopupFull: true });
    } else {
      this.setState({ audioDetailsPopup: false, isAudioPopupFull: false });
      if (this.state.scene) {
        this.state.scene.stopViewMode();
      }
    }
  };

  exitCurateView = () => {
    document.querySelector("html")?.style.height = "auto";
    document.querySelector("body")?.style.height = "auto";
    document.getElementById("root")?.style.height = "auto";
    document.querySelector("#root > div")?.style?.height = "auto";
    document.querySelector("body")?.classList.remove("overflow-controls");
    document.querySelector("html")?.classList.remove("overflow-controls");
    document.getElementById("root")?.classList.remove("overflow-controls");
    document
      .querySelector("#root > div")
      ?.classList.remove("overflow-controls");
  };

  componentWillMount = () => {
    document.querySelector("body")?.classList.add("overflow-controls");
    document.querySelector("html")?.classList.add("overflow-controls");
    document.getElementById("root")?.classList.add("overflow-controls");
    document.querySelector("#root > div")?.classList.add("overflow-controls");
  };

  componentWillUnmount() {
    logger.log("curate component unmount");

    try {
      window.removeEventListener("resize", this.sceneResizeListener);
      window.removeEventListener("orientationchange", this.handleOrientationChange);
      if(this.sceneResizeTimeoutRef.current) {
        clearTimeout(this.sceneResizeTimeoutRef.current);
      }
      if (this.listviewTimeoutRef.current) {
        clearTimeout(this.listviewTimeoutRef.current);
      }
      if(this.exhibitionLoadingCompleteTimeoutRef.current) {
        clearTimeout(this.exhibitionLoadingCompleteTimeoutRef.current);
      }
      if(this.toggleArtworkDetailsRef.current) {
        clearTimeout(this.toggleArtworkDetailsRef.current);
      }
      if(this.resizeAllTimeoutRef.current) {
        clearTimeout(this.resizeAllTimeoutRef.current);
      }
      this.exitCurateView();
      this.state.scene && this.state.scene.cleanup();
      this.state.scene = null;
      this.curateRef = null;
      this.child = null;
      this.exitCurateView();

      document.querySelector("body")?.classList.remove("overflow-controls");
      logger.debug("clean scene state ");
    } catch (error) {
      logger.error("error");
      logger.error(error);
    }
  }

  // This will prevent re-renderings on location search ( queryParams ) changes in the url
  // shouldComponentUpdate(nextProps, nextState) {
  //   // Check if relevant props have changed
  //   if (
  //     nextProps.location.search !== this.props.location.search
  //   ) {
  //     // If relevant props haven't changed, return false to prevent re-render
  //     return false;
  //   }
  //   // Default behavior: allow re-render
  //   return true;
  // }

  componentDidUpdate(prevProps, prevStates) {

    // Runs only in Development Mode!
    if (IS_DEVELOPMENT){
      // ?: Checks which states changed causing re-rendering :)
      // Object.keys(this.state).forEach(key => {
      //   if (this.state[key] !== prevStates[key]) {
      //     logger.log(`This State ========>> '${key}' changed:`, prevStates[key], '->', this.state[key]);
      //   }
      // });
    }

    if (prevStates.exhibitionDetails !== this.state.exhibitionDetails) {
      if (this.state.listview) {
        this.setState({ listViewLoading: false });
      }
    }
    if (prevStates.sidePanel !== this.state.sidePanel) {
      this.resizeLoop();
    }
    if (prevStates.audioDetailsPopup !== this.state.audioDetailsPopup) {
      this.getCurateHeight();
      this.sceneResizeListener();
      this.resizeLoop();
    }
    if(prevStates.selectedArtwork?.data?.id !== this.state.selectedArtwork?.data?.id && this.state.audioDetailsPopup){
      this.getCurateHeight();
      this.sceneResizeListener();
      this.resizeLoop();
    }
    if (
      prevStates.artworkPhotos !== this.state.artworkPhotos &&
      this.state.artworkPhotos?.length > 0
    ) {
      this.getCurateHeight();
    }
    if (prevStates.isActive !== this.state.isActive) {
      this.resizeLoop();
    }
    if (prevStates.loadingProgress !== this.state.loadingProgress) {
      if (this.state.loadingProgress > 99) {

        // Starts the Guided Detailed Mode when loading hits 100%
        this.state.scene.setGuidedNavMode(true);
        this.resizeCurateCanvas();

        // If listview artwork carousel is open and the artwork is selected, focus the artwork
        if(this.state.isArtwork2dModal && this.state.selectedArtwork?.data?.id) {
          this.handleArtworkCardClick(this.state.selectedArtwork?.data?.id, null);
        }
      }
      if (this.state.loadingProgress === 0) {
        this.setState({ isDetailedMode: false, instructed: false });
        clearInterval(this.timeInterval);
      }
    }
    if (prevStates.listview !== this.state.listview) {
      if (this.state.loadingProgress > 99 && this.state.exhibitionDetails) {
        this.setState({ listViewLoading: false, isTootip: false });
      }
    }
    if (prevStates.detailedArtworks !== this.state.detailedArtworks) {
      const { location, queryParams } = this.props;
      // let { artworkId, listview } = this.getLocationSearchIds(location.search);
      let { artworkId, listview } = queryParams;
      if (listview && artworkId) {
        this.handleArtworkClick(artworkId);
      }
    }
  }

  resizeCurateCanvas = () => {
    this.sceneResizeListener();
    this.sceneResizeTimeoutRef.current = setTimeout(() => {
      this.sceneResizeListener();
    }, 100);
  };

  removeUndefinedQueryPart = (val) => {
    let finalQuery = val;
    if (finalQuery.includes("=undefined&")) {
      return finalQuery.split("=undefined&").join("");
    } else if (finalQuery.includes("=undefined")) {
      return finalQuery.split("=undefined").join("");
    } else {
      return val;
    }
  };

  updateHistoryQueryArtworkId = (artworkId) => {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);

    if (rooms?.length > 0 && selectedRoomId) {
      params.set('sceneId', selectedRoomId.toString());
    } else {
      params.delete('sceneId');
    }

    if (artworkId) {
      params.set('artworkId', artworkId.toString());
    } else {
      params.delete('artworkId');
    }

    // Serialize the parameters and clean up undefined parts
    const finalQuery = `?${serialize(Object.fromEntries(params))}`;
    return removeUndefinedQueryPart(finalQuery);
  };

  // addArtworkIdToUrl = (artworkId, audioDetailsPopup = false) => {
  //   if (this.props.history.location.search === "" && artworkId) {
  //     if (this.state.rooms?.length > 0) {
  //       this.props.history.replace(
  //         `${this.props.history.location.pathname}?sceneId=${this.state.selectedRoomId}&artworkId=${artworkId}`
  //       );
  //     } else {
  //       this.props.history.replace(
  //         `${this.props.history.location.pathname}?artworkId=${artworkId}`
  //       );
  //     }
  //   } else if (this.props.history.location.search !== "" && artworkId) {
  //     this.props.history.replace({
  //       search: this.updateHistoryQueryArtworkId(artworkId),
  //     });
  //   } else if (
  //     this.props.history.location.search === "" &&
  //     audioDetailsPopup === false &&
  //     artworkId === null
  //   ) {
  //     this.props.history.replace(`${this.props.history.location.pathname}`);
  //   } else if (
  //     this.props.history.location.search !== "" &&
  //     audioDetailsPopup === false &&
  //     artworkId === null
  //   ) {
  //     this.props.history.replace({
  //       search: this.updateHistoryQueryArtworkId(null),
  //     });
  //   }
  // };

  updateHistoryQueryArtworkId = (artworkId) => {
    // const location = useLocation();
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    if (artworkId === null) {
      params.delete('artworkId');
    } else {
      params.set('artworkId', artworkId);
    }
    return params.toString();
  };

  addArtworkIdToUrl = (artworkId, audioDetailsPopup = false) => {
    const { location, navigate } = this.props;
    const { rooms, selectedRoomId } = this.state;
    const searchParams = new URLSearchParams(location.search);
    const hasArtworkId = artworkId !== null;
    const isSearchEmpty = location.search === '';

    if (isSearchEmpty && hasArtworkId) {
      if (rooms?.length > 0) {
        navigate(`${location.pathname}?sceneId=${selectedRoomId}&artworkId=${artworkId}`, { replace: true });
      } else {
        navigate(`${location.pathname}?artworkId=${artworkId}`, { replace: true });
      }
    } else if (!isSearchEmpty && hasArtworkId) {
      navigate(`${location.pathname}?${this.updateHistoryQueryArtworkId(artworkId)}`, { replace: true });
    } else if (isSearchEmpty && !audioDetailsPopup && artworkId === null) {
      navigate(location.pathname, { replace: true });
    } else if (!isSearchEmpty && !audioDetailsPopup && artworkId === null) {
      navigate(`${location.pathname}?${this.updateHistoryQueryArtworkId(null)}`, { replace: true });
    }
  };

  const toggleListViewInUrl = (listview, targetBlank = false) => {
    const { location, navigate } = this.props;
    const { isArtwork2dModal, selectedArtwork, selectedRoomId } = this.state;
    const queryParams = new URLSearchParams(location.search);
    
    if (listview) {
      if (!queryParams.has('listview')) {
        queryParams.set('listview', 'true');
      } else if (queryParams.get('listview') === 'false') {
        queryParams.set('listview', 'true');
      }
      if(!queryParams.has('sceneId')) {
        queryParams.set('sceneId', selectedRoomId);
      }
    } else {
      if (queryParams.get('listview') === 'true') {
        queryParams.set('listview', 'false');
      }
    }

    if (!isArtwork2dModal) {
      const artworkId = selectedArtwork?.data?.id;
      if (artworkId) {
        queryParams.delete('artworkId');
        if (!targetBlank) {
          this.toggleArtworkDetailsRef.current = setTimeout(() => {
            this.toggleAudioDetailsPopup(false, null);
          }, 100);
        }
      }
    }

    const updatedQuery = queryParams.toString();
    if (targetBlank) {
      window.localStorage.setItem('isOpenedFromCollaborations', 'false');
      const win = window.open(`?${updatedQuery}`, '_blank');
      win.focus();
    } else {
      navigate(`?${updatedQuery}`, { replace: true });
    }
  };

  toggleAudioDetailsPopup = (
    audioDetailsPopup,
    artworkId,
  ) => {
    const {
      state,
      addArtworkIdToUrl,
    } = this;
    const {
      detailedArtworks,
      listview,
      isArtwork2dModal
    } = state;

    if(listview && !isArtwork2dModal){
      addArtworkIdToUrl(null);
    }
    else {
      addArtworkIdToUrl(artworkId, audioDetailsPopup);
    }
    const selectedArtwork = detailedArtworks.filter(
      (artwork) => artwork.data.id == artworkId
    );
    if (selectedArtwork[0]) {
      this.setState(
        {
          isArtworkDefaultStateActive: audioDetailsPopup,
          selectedArtwork: selectedArtwork[0],
          isMobileArtwork: isMobileDevice(),
        }
      );
    } else {
        if (this.artworkDetailsRef?.current) {
          this.artworkDetailsRef.current.reset();
          this.stopArtworkViewMode();
        }
    }
  }

  onArtworkClick = (isSidebar) => {
    this.setState(
      {
        isSidebar: isSidebar,
        viewMap: false,
        viewDesc: false,
        viewEvent: false,
      },
      () => {
        this.sceneResizeListener();
      }
    );
    if (this.state.scene) {
      this.state.scene.stopViewMode();
    }
  };

  loadExhibitionDetails = async () => {
    //@ts-ignore
    let exhibitionDetails = {};
    if(this.props.isPreview) {
      exhibitionDetails = await fetchExhibitionsDetails(
          this.props.params.id,
          !!this.props.isPreview
      );
    }
    else {
        exhibitionDetails = this.state.curateV2Response.details;
    }
    if (exhibitionDetails && exhibitionDetails.data) {
      let { startedAt, endedAt } = exhibitionDetails.data;
      exhibitionDetails.data.startedAt = formatDate(startedAt);
      exhibitionDetails.data.endedAt = formatDate(endedAt, true);
      if (rudderStackEvents) {
        rudderStackEvents.mount({
          exhibitionId: exhibitionDetails.data.id,
          exhibitionName: exhibitionDetails.data.name,
          galleryName: exhibitionDetails.data.organisation.data.name,
          galleryId: exhibitionDetails.data.organisation.data.id,
          exhibitionUuid: exhibitionDetails.data.uuid,
        });
        rudderStackEvents.onExhibitionEnter();
      }
      this.setState({
        exhibitionDetails: exhibitionDetails,
        isFavourite: exhibitionDetails.data.isFavourite,
        loadingPageDetails: exhibitionDetails.data,
      });
    }
  };

  private fetchModel = async (modelId) => {
    const modelResp = await axios({
      method: "get",
      url: `${NURBS_API_URL}/assets/${modelId}`,
    });
    return modelResp.data;
  };

  handleClickArtwork = (artworkId, isSoftClick = false) => {
    const { profile } = this.props;
    if (this.props.isLoggedIn) {
      this.state?.scene?.setUserId(profile?.profileData?.id);
    }
    this.setState({ isDetailedMode: !!artworkId });
    this.toggleAudioDetailsPopup(!!artworkId, artworkId, false, isSoftClick);
    this.sceneResizeListener();
  };

  setEventFromProps = () => {
    this.setState({
      eventFromProps: false,
    });
  };

  //Handle progress updates from CurateView
  loadingProgressHandler = (loadingProgress) => {
    if (loadingProgress === 100) {
      logger.info("callBackOnRoomLoad = ", this.state.callBackOnRoomLoad);
      this.state.callBackOnRoomLoad &&
        this.handleArtworkCardClick(this.state.callBackOnRoomLoad, null);
      this.setState({
        callBackOnRoomLoad: null,
      });
      this.setState({
        loadingProgress: 100
      });
    } else {
      this.setState({
        loadingProgress
      });
    }
  };

  onVideoStateChange = (artworkId, state) => {}

  getLocationSearchIds = (hash) => {
    var array = hash.substring(1).split("&");
    var values,
      form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }
    return {
      sceneId: parseInt(form_data["sceneId"]),
      artworkId: parseInt(form_data["artworkId"]),
      eliminateSignedUrl: form_data["eliminateSignedUrl"],
      listview: form_data["listview"] === "true",
    };
  };

  updateHistoryQuerySceneId = (newSceneId) => {
    var hash = this.props.location.search;
    var array = hash.substring(1).split("&");
    logger.debug("array => ", array, hash);
    logger.debug("array => hash", hash, this.props.location.search);
    var values,
      form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }
    delete form_data["artworkId"];
    if (newSceneId) {
      form_data["sceneId"] = newSceneId.toString();
    } else {
      delete form_data["sceneId"];
    }

    const finalQuery = `?${this.serialize(form_data)}`;
    return `${this.removeUndefinedQueryPart(finalQuery)}`;
  };

  onSoftArtworkChange = (artworkId) => {
    if (artworkId) {
      const artwork = this.state.detailedArtworks.filter(
        ({ data }) => data.id == artworkId
      );
      this.setState(
        {
          softArtworkId: artworkId,
          selectedSoftArtwork: artwork[0].data,
        }
      );
    } else {
      this.setState({
        softArtworkId: null,
      });
    }
  };

  onHoverArtworkChanged = (artworkId) => {
    if (!isMobileDevice() && window.innerWidth > 990) {
      this.onSoftArtworkChange(artworkId);
    }
  };

  onSeenArtworkChanged = (artworkId) => {};

  generateSignedUrls = (data) => {
    const newData = { ...data };
    newData.data.model.data.signedUrl = constructCFUrl(newData.data?.model);
    newData.data.HDRLightMap.data.signedUrl = constructCFUrl(newData.data?.HDRLightMap);
    // jpeg format lightmap
    newData.data.HDRLightMapJpeg = {
      data: {
        signedUrl: constructHDRCFUrl(newData.data?.HDRLightMap)
      }
    };

    newData.data.HDRProbe.data.signedUrl = constructCFUrl(newData.data?.HDRProbe);
    // jpeg format probe
    newData.data.HDRProbeJpeg = {
      data: {
        signedUrl: constructHDRCFUrl(newData.data?.HDRProbe)
      }
    }
    if(newData?.data?.bundle) {
      newData.data['bundle']['data']['signedUrl'] = constructCFUrl(newData.data?.bundle);
    }
    if(newData.data?.ReflectionMap) {
      // jpeg format Reflection Map
      newData.data.ReflectionMapJpeg = {
        data: {
          signedUrl: constructHDRCFUrl(newData.data?.ReflectionMap)
        }
      }
    }

    return newData;
  };

  onDoneCurateLoading = async () => {
  }

  processArtworksArray = async (linkedArtworks, currentSceneId, eliminateSignedUrl = false) => {
    const assets = new Map();
    let artworks  = [];
      const artworksInCurrentRoom = linkedArtworks?.dimensions.filter(
          ({ data }) => data.exhibitionSceneId == currentSceneId
      ).length;
      artworks = await Promise.all(
          linkedArtworks?.dimensions
              .filter(({ data }) => data.exhibitionSceneId == currentSceneId)
              .map(async ({ data }) => {
                // if()
                const artworkType = data.artwork.data.type;
                const ambientAudio = data.ambientAudio
                    ? constructAudioUrl(data.ambientAudio.data?.audio)
                    : "";
                
                const artworkAudio = data?.channels?.length > 0 && data?.channels[0]?.audio
                    ? constructAudioUrl(data?.channels[0]?.audio)
                    : "";
                console.log('data for audio => ', data?.channels[0]?.audio?.data);
                let fileUrl;
                const resource =
                    artworkType === "2d"
                        ? data.artwork.data.image
                        : artworkType === "video"
                            ? data.artwork.data.videoThumbnail
                            : data.artwork.data.thumbnail;
                // TODO, handle in exhib details
                // thumbnail, videoThumbnail, HDRArtworkMap, HDRAssetMap

                // New key: basisURL
                // var size = "1024sq_";
                //
                // if (parseInt(data.artwork.data.width) + parseInt(data.artwork.data.height) > 100) {
                //     size = "2048sq_";
                // }
                // newImage['basisURL'] = resource.data.CFURL + size + resource.data.name + ".basis" + resource.data.signature;

                // New key: modelUrl -> artwork.data.modelUrl
                // let resp = await this.fetchModel(data.artwork.data.bundleId);
                // fileUrl = resp.data.signedUrl;

                const thumbnailSignedUrl = data.artwork.data.thumbnail
                    ? eliminateSignedUrl ? constructImageUrl(data.artwork.data.thumbnail, 'raw') : data.artwork.data.thumbnail.data.signedUrl
                    : null;
                if (artworkType === "3d") {
                  if (isMobileDevice()) {
                    if (data.artwork.data.bundleId) {
                      let resp = await this.fetchModel(data.artwork.data.bundleId);
                      fileUrl = resp.data.signedUrl;
                    }
                  } else {
                    if (data.artwork.data.highBundleId) {
                      let resp = await this.fetchModel(
                          data.artwork.data.highBundleId
                      );
                      fileUrl = resp.data.signedUrl;
                    } else if (data.artwork.data.bundleId) {
                      let resp = await this.fetchModel(data.artwork.data.bundleId);
                      fileUrl = resp.data.signedUrl;
                    }
                  }
                } else if (artworkType === "2d") {
                  fileUrl = resource.data.signedUrlLP_1024;
                } else if (artworkType === "video") {
                  // TODO, handle in exhib details
                  fileUrl = data.artwork.data.videoThumbnail.data.signedUrl;
                }

                const newImage = {};
                if (artworkType === "2d") {
                  // TODO, handle in exhib details
                  newImage["urlPNG"] = data.artwork.data.thumbnail
                      ? eliminateSignedUrl ? constructImageUrl(data.artwork.data.thumbnail, 'raw') : data.artwork.data.thumbnail.data.signedUrl
                      : null;
                }
                if (!!ambientAudio) {
                  // const audioElement = document.createElement("audio");
                  // audioElement.src = ambientAudio;
                  // audioElement.id = data.artworkId;
                  // audioElement.preload = "auto";
                  // audioElement.loop = true;
                  // document.getElementById('ambientAudio').appendChild(audioElement);
                }

                newImage["price"] =
                    data &&
                    data.artwork &&
                    data.artwork.data &&
                    data.artwork.data.price;
                // newImage['id'] = data.id;
                newImage["id"] = this.props.params.id;
                newImage["url"] = fileUrl;
                newImage["thumbnail"] = thumbnailSignedUrl;
                newImage["ambientAudio"] = ambientAudio;
                newImage["artworkAudio"] = artworkAudio;
                newImage["title"] = data.artwork.data.title;
                newImage["artistName"] = data.artwork.data.artist.data.name;
                newImage["isFavourite"] = data.artwork.data.isFavourite;
                newImage["type"] = artworkType !== "3d" ? "2d" : "3d";
                newImage["squareURL"] = isMobileDevice()
                    ? eliminateSignedUrl ? constructImageUrl(resource, 'raw') : resource && resource.data && resource.data.signedUrl
                    : eliminateSignedUrl ? constructImageUrl(resource, 'raw') : resource && resource.data && resource.data.signedUrl;
                newImage["canvasColor"] = data.canvasColor || "#000000";
                newImage["isCanvas"] = data.isCanvas;
                newImage["isCollateral"] = data.isCollateral;
                newImage["skipLightmap"] = data.skipLightmap;

                var size = "1024sq_";

                if (!isMobileDevice()) {
                  size = "2048sq_";
                } else if (isMobileDevice() && artworksInCurrentRoom <= 10) {
                  size = "2048sq_";
                } else if (
                    isMobileDevice() &&
                    artworksInCurrentRoom > 10 &&
                    artworksInCurrentRoom <= 20
                ) {
                  size = "1024sq_";
                } else if (
                    isMobileDevice() &&
                    artworksInCurrentRoom > 20 &&
                    artworksInCurrentRoom <= 35
                ) {
                  size = "512sq_";
                } else if (isMobileDevice() && artworksInCurrentRoom > 35) {
                  size = "256sq_";
                }

                newImage["basisURL"] = data?.artwork?.data?.basisURL
                    ? data.artwork.data.basisURL
                    : resource?.data?.CFURL +
                    size +
                    resource?.data?.name +
                    ".basis" +
                    resource?.data?.signature;
                newImage["ktx2URL"] = resource?.data?.CFURL +
                    "mipmap_q250_" +
                    size +
                    resource?.data?.name +
                    ".ktx2" +
                    resource?.data?.signature;

                if (isMobileDevice()) {
                  newImage["ktx2URL_LOW_MIPMAP"] = resource?.data?.CFURL +
                      "mipmap_q250_" +
                      size +
                      resource?.data?.name +
                      ".ktx2" +
                      resource?.data?.signature;
                  newImage["ktx2URL_HIGH_MIPMAP"] = resource?.data?.CFURL +
                      "mipmap_q250_" +
                      "2048sq_" +
                      resource?.data?.name +
                      ".ktx2" +
                      resource?.data?.signature;
                  newImage["ktx2URL_LOW"] = resource?.data?.CFURL +
                      size +
                      resource?.data?.name +
                      ".ktx2" +
                      resource?.data?.signature;
                  newImage["ktx2URL_HIGH"] = resource?.data?.CFURL +
                      "2048sq_" +
                      resource?.data?.name +
                      ".ktx2" +
                      resource?.data?.signature;
                } else {
                  newImage["ktx2URL_LOW_MIPMAP"] =  resource?.data?.CFURL +
                      "mipmap_q250_" +
                      size +
                      resource?.data?.name +
                      ".ktx2" +
                      resource?.data?.signature;
                  newImage["ktx2URL_HIGH_MIPMAP"] = resource?.data?.CFURL +
                      "mipmap_q250_" +
                      "2048sq_" +
                      resource?.data?.name +
                      ".ktx2" +
                      resource?.data?.signature;
                  newImage["ktx2URL_LOW"] = resource?.data?.CFURL +
                      size +
                      resource?.data?.name +
                      ".ktx2" +
                      resource?.data?.signature;
                  newImage["ktx2URL_HIGH"] = resource?.data?.CFURL +
                      "2048sq_" +
                      resource?.data?.name +
                      ".ktx2" +
                      resource?.data?.signature;
                }

                newImage["previewImage"] =
                    artworkType === "2d"
                        ? resource && resource.data && constructImageUrl(resource)
                        : artworkType === "video"
                            ? constructImageUrl(data.artwork.data.videoThumbnail)
                            : constructImageUrl(data.artwork.data.thumbnail);
                newImage["artworkId"] = data.artworkId;
                newImage["frame"] = data.frame === "1" || data.frame === true;
                newImage["frameBack"] =
                    data.frameBack === "1" || data.frameBack === true;
                newImage["frameGlass"] =
                    data.frameGlass === "1" || data.frameGlass === true;
                newImage["trackingLight"] =
                    data.trackingLight === "1" || data.trackingLight === true;
                if (assets.has(data.asset.data.id)) {
                  newImage["asset"] = assets.get(data.asset.data.id);
                } else {
                  const asset = {
                    ...data.asset,
                    data: {
                      ...data.asset.data,
                      file: {
                        ...data.asset.data.file,
                        data: {
                          ...data.asset.data.file.data,
                          signedUrl: constructCFUrl(data.asset.data.file)
                        }
                      }
                    }
                  }
                  //console.log('asset = ', asset)
                  assets.set(data.asset.data.id, asset);
                  newImage["asset"] = asset;
                }
                newImage["onFloor"] = data.onFloor;
                newImage["lightmapURL"] = data.HDRArtworkMap
                    ? eliminateSignedUrl ? constructCFUrl(data.HDRArtworkMap) : data.HDRArtworkMap.data.signedUrl
                    : null;
                newImage["lightmapURLJpeg"] = data.HDRArtworkMap
                    ? eliminateSignedUrl ? constructHDRCFUrl(data.HDRArtworkMap) : data.HDRArtworkMap.data.signedUrl
                    : null;
                newImage["pedestalLightMap"] = data.HDRAssetMap
                    ? eliminateSignedUrl ? constructCFUrl(data.HDRAssetMap) : data.HDRAssetMap.data.signedUrl
                    : null;
                newImage["pedestalLightMapJpeg"] = data.HDRAssetMap
                    ? eliminateSignedUrl ? constructHDRCFUrl(data.HDRAssetMap) : data.HDRAssetMap.data.signedUrl
                    : null;
                newImage["frameSettings"] = {
                  frameGap: data.frameGap,
                  frameWidth: data.frameWidth,
                  frameDepth: data.frameDepth,
                  spotLight: data.spotLight,
                  spotLightDistance: data.spotLightDistance,
                  spotLightHeight: data.spotLightHeight,
                  spotLightSoft: data.spotLightSoft,
                  spotLightTemp: data.spotLightTemp,
                  spotLightAngle: data.spotLightAngle,
                };
                newImage["dimensions"] = {
                  height: parseFloat(data.artwork.data.height),
                  width: parseFloat(data.artwork.data.width),
                  depth: parseFloat(data.artwork.data.depth),
                };
                if (data.size) {
                  newImage["size"] = data.size;
                }
                if (artworkType === "video") {
                  const name = data.artwork.data.video.data.name.includes('mov') ? data.artwork.data.video.data.name.replace('mov', 'mp4') : data.artwork.data.video.data.name;
                  newImage["video"] =
                  data.artwork.data.video.data.CFURL + 'MOBILE_' + name + data.artwork.data.video.data.signature;
                }
                if (data.x !== null && data.y !== null && data.z !== null) {
                  newImage["visible"] = true;
                  newImage["position"] = {
                    x: data.x,
                    y: data.y,
                    z: data.z,
                  };
                  newImage["rotation"] = data.rotation;
                  newImage["axisRotation"] = data.axisRotation;
                  newImage["size"] = data.size;
                }
                return newImage;
              })
      );
    return artworks;
  }

  public getData = async (roomId = null, shouldLoad3d = false, isRoomSwitch = false) => {
    // @ts-ignore
    this.state.scene && this.state.scene.cleanup();
    const {
      location,
      // match: {
      //   params: { id },
      // },
      queryParams,
      profile,
    } = this.props;
    // let { artworkId, sceneId, eliminateSignedUrl } = this.getLocationSearchIds(location.search);
    let { artworkId, sceneId, eliminateSignedUrl } = queryParams;
    console.log('artworkId, sceneId, eliminateSignedUrl => ', artworkId, sceneId, eliminateSignedUrl);

    let details = null;
    let data = null;
    if (
      // location.state &&
      // location.state.curateState &&
      // location.state.curateState.models
      false
    ) {
      // details = { ...location.state.curateState };
      // data = details.models;
      // let state = { ...location.state };
      // delete state.curateState;
      // this.props.history.replace({ ...location, state });
    } else {
      if(this.props.isPreview) {
        details = await fetchCurateDetails(
            this.props.params.id,
            (!this.state.listview && roomId) ?? sceneId,
            this.state.listview,
            this.props.isPreview,
            true,
            eliminateSignedUrl !== "false"
        );
      }
      else {
        details = this.state.curateV2Response.curateData;
      }

      data = details && details.models && details.models[0] ?? details && details.data.models && details.data.models[0];
      // TOD: Curate package doesn't fetch files over CFURls, so this wont work untile curate package fixes it.
      if(eliminateSignedUrl !== "false") {
        data = this.generateSignedUrls(data);
      }
      if (
        this.state.exhibitionDetails?.data?.version > 0 &&
        data?.hasOwnProperty("renderVersion")
      ) {
        data = {
          data: {
            ...data,
            room: {
              data: {
                renderVersion: data.renderVersion,
              },
            },
          },
        };
      } else {
        if (data?.hasOwnProperty("renderVersion")) {
          data = {
            data: {
              ...data,
              room: {
                data: {
                  renderVersion: data.renderVersion,
                },
              },
            },
          };
        } else {
          data = {
            data: {
              ...data?.data,
              room: {
                data: {
                  renderVersion: 0,
                },
              },
            },
          };
        }
      }
    }
    const currentSceneId = roomId || data?.data?.data?.id || data?.data?.id;
    this.setState({
      defaultArtworkId: !isRoomSwitch ? artworkId : null,
      selectedRoomId: currentSceneId,
    });

    var scene = null;
    if (shouldLoad3d) {
      data["animation_props"] = {
        intro_anim: data?.data?.data?.introAnim || data?.data?.introAnim,
        intro_anim_data: data?.data?.data?.introAnimData || data?.data?.introAnimData,
      };
      (data["isLockedRoom"] = data?.data?.data?.isLockedInDetailsMode ?? data?.data?.isLockedInDetailsMode),
        this.setState({
          isLockedRoom: data['isLockedRoom'] === 1
        });
      let linkedArtworks = {};
      linkedArtworks['dimensions'] = this.state.curateV2Response.dimensions;

      if(this.props.isPreview) {
        linkedArtworks = await fetchCurateLinkedArtworks(
            this.props.match.params.id,
            (!this.state.listview && roomId) ?? sceneId,
            false,
            this.props.isPreview,
            eliminateSignedUrl !== "false"
        );
      }

      const artworks = await this.processArtworksArray(linkedArtworks, currentSceneId, eliminateSignedUrl !== "false")

      scene = new CurateView(
        "curate-3d-wrapper",
        data,
        this.loadingProgressHandler,
        this.handleClickArtwork,
        profile && profile.profileData && profile.profileData.id,
        this.onVideoStateChange,
        this.closeDetails,
        this.onGuidedModeArtworkChange,
        this.onHoverArtworkChanged,
        this.onSeenArtworkChanged,
        this.onDoneCurateLoading,
        artworks
      );
      const toneMappingSettings = {
        toneMappingExposure: data.data.exposure,
        toneMappingWhitePoint: data.data.whitePoint,
        toneMappingTemperature: data.data.temperature,
        toneMappingWhitePointLower: data.data.contrast,
        toneMappingSaturation: data.data.saturation,
      };
      scene.setTonemappingSettings(toneMappingSettings);
      this.setState({
        artworksList: artworks,
        defaultArtworkId: this.state.defaultArtworkId || artworks[0]?.artworkId,
        detailedArtworks: linkedArtworks.dimensions.map((detail) => {
          return {
            data: {
              ...detail.data.artwork.data,
              artworkAudio: detail.data?.channels && detail?.data?.channels[0]?.audio ? constructAudioUrl(detail?.data?.channels[0]?.audio) : "",
              isCollateral: detail.data.isCollateral,
              exhibitionSceneId: detail.data.exhibitionSceneId,
            },
          };
        }),
        isLoadingArtworks: false,
      })
    }

    if (details) {
      this.setState({ introVideoUrl:  constructVideoUrl(details?.models[0]?.data?.introVideo), introVideoPoster: details?.models[0]?.data?.introVideo?.data?.signedVideoThumbnail })

      let linkedArtworks = {};
      if(this.props.isPreview) {
        linkedArtworks = await fetchCurateLinkedArtworks(
            this.props.params.id,
            (!this.state.listview && roomId) ?? sceneId,
            false,
            this.props.isPreview,
            eliminateSignedUrl !== "false"
        );
        const artworks = await this.processArtworksArray(linkedArtworks, currentSceneId, eliminateSignedUrl !== "false")
        scene && scene.loadPlacementConfig(artworks);
        this.setState({
          artworksList: artworks,
          defaultArtworkId: this.state.defaultArtworkId || artworks[0]?.artworkId,
          detailedArtworks: linkedArtworks.dimensions.map((detail) => {
            return {
              data: {
                ...detail.data.artwork.data,
                artworkAudio: detail.data?.channels && detail?.data?.channels[0]?.audio ? constructAudioUrl(detail?.data?.channels[0]?.audio) : "",
                isCollateral: detail.data.isCollateral,
                exhibitionSceneId: detail.data.exhibitionSceneId,
              },
            };
          }),
          isLoadingArtworks: false,
        })
      }
      this.setState(
          {
            selectedRoom: {
              label: data?.data?.data?.name
                  ? data?.data?.data?.name
                  : data?.data["name"],
              value: data?.data?.data?.id,
            },
            scene,
          },
          () => {}
      );
    }
    window.addEventListener("resize", this.resizeAll);
    // window.addEventListener("resize", this.sceneResizeListener);
  };

  sceneResizeListener = () => {
    if (this.state.scene) {
      try {
        this.state.scene.resize();
      } catch (error) {
        logger.error(error);
      }
    }
  };

  onGuidedModeArtworkChange = (artwork3DId, next, fromSwipe) => {
    const { isLockedRoom, isArtwork2dModal } = this.state;
    const curateArtworkId =
      !this.state.instructed && !isLockedRoom
        ? this.state.defaultArtworkId
        : artwork3DId;

    if (isLockedRoom) {
      logger.log("onguided mode artwork change _> focus on artwork ");
      this.state.scene && this.state.scene.focusOnArtwork(curateArtworkId);
    }
    const { location, queryParams } = this.props;
    // let { artworkId } = this.getLocationSearchIds(location.search);
    let { artworkId } = queryParams;
    if (this.state.audioDetailsPopup || this.state.isArtworkDefaultStateActive) {
      this.toggleAudioDetailsPopup(true, isArtwork2dModal ? (artworkId || curateArtworkId) : curateArtworkId);
    }
    if (!this.state.instructed) {
      this.setState(
        {
          instructed: true,
          isDetailedMode: isLockedRoom || !!artworkId,
          artworkLabel: true
        },
        () => {
          if (isLockedRoom || !!artworkId) {
            this.setArtworkScene(
              curateArtworkId,
              null
            );
            this.toggleAudioDetailsPopup(true, isArtwork2dModal ? (artworkId || curateArtworkId) : curateArtworkId);
          }
        }
      );
    }
  };

  getSelectedTooltips = () => {
    // Setting up tooltips text based on the states of desktop view and mobile view for detailed mode or free mode. ? We are not using it for now :)
    // const selectedTooltip =
    //   window.innerWidth < 990
    //     ? this.state.isMobileArtworkExpanded
    //       ? tooltips.mobileDetailedMode
    //       : tooltips.mobile
    //     : this.state.isDetailedMode
    //     ? tooltips.desktopDetailedMode
    //     : tooltips.desktop;
    // this.setState({ selectedTooltip, selectedTooltipIndex: 0 }, () => {
    //   this.handleAllTooltips();
    // });
  };

  closeDetails = (received) => {
    // This prevents the close event to be fired if the 3d view is open from artwork carousel
    if (this.state.instructed) {
      // Stops the Guided Detailed Mode when detailed Mode gets false
      this.state.scene.setGuidedNavMode(false);
      this.setState({ isDetailedMode: received, isArtworkDefaultStateActive: received, audioDetailsPopup: received }, () => {
        this.getCurateHeight();

        rudderStackEvents &&
        rudderStackEvents.onArtworkDetail(
          this.state.selectedArtwork?.id,
          "close_artwork_view"
        );
        this.toggleAudioDetailsPopup(false, null);
        this.setState({
          isGuidedModeOnMobile: false,
        });
        this.sceneResizeListener();

        if (this.state.scene) {
          this.state.scene.stopViewMode();
        }
      });
      this.focusCanvas();
    }
  };

  closeDetailsOnMobile = (received) => {
    this.toggleAudioDetailsPopup(false, null);
    this.setState({
      isGuidedModeOnMobile: false,
    });
    this.sceneResizeListener();
    this.focusCanvas();
  };

  // Ahmed: what is this for ??
  setVideoArtworkStateByID = ({ artworkId, state }, userId) => {
    if (this.state.scene) {
      this.state.scene.setUserId(userId);
      this.state.scene.setVideoArtworkStateByID(artworkId, state);
    }
  };

  handleSetCameraTransform = (syncData) => {
    this.state.scene.setCameraTransform(syncData);
  };
  handleRemoveDetails = () => {
    this.closeDetails(true);
    this.handleArtworkCardClick(null, true);
  };

  handleIfNoSyncData = (parsed) => {
    if (parsed && parsed.artworkId) {
      this.state.scene.setUserId(parsed.userId);
      this.handleArtworkCardClick(parsed.artworkId, true);
    } else {
      this.handleRemoveDetails();
    }
  };
  triggerArtworkCardClick = (data) => {
    try {
      let parsed = JSON.parse(data);
      if (parsed.syncData) {
        this.handleSetCameraTransform(parsed.syncData);
      }
      if (parsed.video) {
        this.setVideoArtworkStateByID(parsed.video, parsed.userId);
      }
      if (!parsed.syncData && !parsed.video) {
        this.handleIfNoSyncData(parsed);
      }
    } catch (e) {
      logger.error("Failed: ", e);
    }
  };
  stopAlreadyPlayingVideo = () => {
    const videoElement = document.getElementById("hiddenVideoElement");
    if (videoElement) {
      videoElement.pause();
    }
  };

  handleArtworkCardClick = (
    artworkId,
    isUserIdUpdated,
    isGuidedModeOnMobile = false
  ) => {
    this.setState({ isDetailedMode: !!artworkId });
    this.setState({
      isGuidedModeOnMobile: isGuidedModeOnMobile,
    });
    if (window.innerWidth <= 990) {
      this.setState({ viewMap: false, isSidebar: false });
    }
    this.getCurateHeight();
    this.handleClickArtwork(artworkId, false);
    this.state.scene && this.state.scene.focusOnArtwork(artworkId);
  };

  toggleFavourite = async () => {
    const resp = await favouriteExhibition(
      this.state.exhibitionDetails?.data?.id
    );
    if (resp.data) {
      this.setState({ isFavourite: resp.data.isFavourite });
    }
  };

  toggleArtworkFavourite = async (id) => {
    if (rudderStackEvents) {
      rudderStackEvents.onArtworkBookmark(id);
    }
    const resp = await favouriteArtworks(id);
    if (resp.data) {
      let modified = this.state.artworksList.map((artwork) => {
        if (artwork.artworkId == id) {
          return {
            ...artwork,
            isFavourite: resp.data.isFavourite,
          };
        }
        return artwork;
      });
      this.setState({
        artworksList: modified,
      });
    }
  };

  getFavouriteStatus = (artworkId) => {
    const { artworksList } = this.state;
    let filtered = artworksList.filter(
      (artwork) => artwork.artworkId == artworkId
    );
    if (filtered && filtered[0]) {
      return filtered[0].isFavourite;
    } else {
      return false;
    }
  };

  handleHeaderBtnClick = (
    viewDesc: boolean,
    viewMap: boolean,
    viewEvent: boolean,
    viewAuth: boolean,
    viewComment: boolean,
    enquire: boolean = false,
    isSidebar: boolean = true
  ) => {
    this.setState(
      {
        viewDesc: viewDesc,
        viewMap: viewMap,
        viewEvent: viewEvent,
        isSidebar,
        enquire,
      },
      () => {
        this.sceneResizeListener();
      }
    );
  };

  handleAudioChange = (val) => {
    this.setState({ isCurateAudio: val }, () => {
      if(isMobileDevice() || window.innerWidth <= 990) {
        this.sceneResizeListener();
      }
    });
  };

  isMuseumType = () => {
    const orgType = this.state.exhibitionDetails &&
          this.state.exhibitionDetails.data.organisation.data.type;
    return orgType === "museum";
  };

  // canEnquire = () => {
  //   return !this.isMuseumType();
  // };

  handleAllTooltips = () => {
    // const { selectedTooltip, selectedTooltipIndex } = this.state;

    // if (!selectedTooltip || selectedTooltip.length === 0) {
    //   return;
    // }

    // const updateSelectedTooltip = () => {
    //   if (selectedTooltipIndex < selectedTooltip.length) {
    //     this.setState((prevState) => ({
    //       selectedTooltipIndex: prevState.selectedTooltipIndex + 1,
    //     }));
    //   } else {
    //     this.setState({ selectedTooltip: null });
    //   }
    // };

    // this.setState(
    //   { selectedTooltipLabel: selectedTooltip[selectedTooltipIndex] },
    //   updateSelectedTooltip
    // );
  };

  handleExit = () => {
    const { navigate } = this.props;
    logger.log("handle exit");
    this.exitCurateView();
    // TODO: What does this function do
    // props.handleExit();

    this.stopAlreadyPlayingVideo();

    const isOpenedFromCollaborations = window.localStorage.getItem('isOpenedFromCollaborations');

    if (window.history.length === 1 && isOpenedFromCollaborations === 'true') {
      window.close();
    } else if (window.history.length > 2) {
      // TODO: This does not unmount
      navigate(-1); // Go back in history
    } else {
      navigate("/discover"); // Redirect to /discover
    }
  };

  handleRoomSelection = (value) => {
    this.handleHeaderBtnClick(false, false, false, false, false, false, false);
    this.closeDetails(false);
    this.setState({ sidePanel: false, curateLoadingState: [] });
    const newRooms = [...this.state.rooms];
    const selected = newRooms.filter((room) => room.value == value);
    if (!!this.state.scene && this.state.recentVideoArtwork.id) {
      this.state.scene.setVideoArtworkStateByID &&
        this.state.scene.setVideoArtworkStateByID(
          this.state.recentVideoArtwork.id,
          false
        );
    }
    this.setState(
      {
        introVideoUrl: '',
        introVideoPoster: '',
        audioDetailsPopup: false,
        isArtworkDefaultStateActive: false,
        artworkMinimizedActive: false,
        isIntroVideoEnded: true,
        isRoomLoading: true,
        loadingProgress: 0,
        selectedRoom: selected[0],
        showSplashScreen: false,
        exitScreen: false,
        heighLightMessage: true,
        roomChangingState: { isChanging: false, value: null },
        isRoomSwitchModal: false,
        curateV2Response: {}
      },
      async () => {
        const { queryParams, navigate } = this.props;
        const { artworkId, sceneId } = queryParams;
        // let { artworkId, sceneId } = this.getLocationSearchIds(
        //   this.props.location.search
        // );


        if (artworkId || this.state.event?.data?.id || sceneId || value) {
          const query = this.updateHistoryQuerySceneId(value);
          navigate(`?${query}`, { replace: true });
        } else {
          navigate(`?sceneId=${value}`, { replace: true });
        }

        // if (artworkId || this.state.event?.data?.id || sceneId) {
        //   this.props.history.replace({
        //     search: this.updateHistoryQuerySceneId(value),
        //   });
        // } else {
        //   this.props.history.replace({ search: `?sceneId=${value}` });
        // }

        if(!this.props.isPreview) {
          const curateV2Response = await fetchCurateDetailsV2(this.props.params.id, value, false, true);
          this.setState({ curateV2Response: curateV2Response });
        }
        this.getData(value, true, true);
      }
    );
  };

  handleHelp = () => {
    this.setState(
      {
        showSplashScreen: false,
        splashScreenByInfo: false,
      },
      () => {
        if (isMobileDevice() || window.innerWidth <= 990) {
          this.setState({ sidePanel: false });
        }
      }
    );
  };

  setArtwork2dModal = (artworkId) => {
    this.addArtworkIdToUrl(artworkId);
    if (this.state.detailedArtworks.length > 0) {
      const selectedArtwork = this.state.detailedArtworks.filter(
        (artwork) => artwork.data.id == artworkId
      );
      if (selectedArtwork[0]) {
        this.setState({
          isArtwork2dModal: true,
          selectedArtwork: selectedArtwork[0],
        });
      }
    }
  };

  handleArtworkClick = (id) => {
    const selectedArtwork = this.state.detailedArtworks.filter(
      (artwork) => artwork.data.id === id
    );
    rudderStackEvents.onArtworkClick(id, selectedArtwork[0]?.data?.uuid);
    if (this.state.listview) {
      this.setArtwork2dModal(id);
      return;
    }
    this.handleArtworkCardClick(id, null);
    if (isMobileDevice() || window.innerWidth < 990) {
      this.setState({ sidePanel: false });
    }
  };

  setArtworkModal = (val) => {
    this.setState({ isArtworkModal: val });
  };

  setArtworkPhotos = (val) => {
    this.setState({ artworkPhotos: val });
  };

  handleSelectedArtwork = (val, photos, index) => {
    this.setState({ artworkAdditionalPhotos: photos, artworkModalSelectedIndex: index, isArtworkModal: val });
  };

  serialize = (obj) => {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  };

  setArtworkScene = (artworkId) => {
    logger.log("set artwork scene > start view for id");
    this.state.scene && this.state.scene.focusOnArtwork(artworkId);
  };

  getCurateArtworkAddress = () => {
    const curateLink = window.location.href.slice(
      0,
      window.location.href.indexOf("/curate") + 7
    );
    var hash = window.location.search;
    var array = hash.substring(1).split("&");
    var values,
      form_data = {};

    for (var i = 0; i < array.length; i += 1) {
      values = array[i].split("=");
      form_data[values[0]] = values[1];
    }

    delete form_data["eventId"];

    return `${curateLink}?${this.serialize(form_data) || ""}`;
  };

  resizeLoop = () => {
    // TODO: Needs to be handled by Ahmed :)

    // ? This Function keeps on calling the scene resizeCurateCanvas function to resize the canvas with the help of this interval and ends the interval after 1500ms.
    // ? Calling resizeCurateCanvas for once or multiple times was not resizing canvas properly on browser resize.
    const interval = setInterval(() => {
      this.resizeCurateCanvas();
    }, 50);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      clearTimeout(timeout);
    }, 1500);
  };

  getCurateHeight = () => {
    const { audioDetailsPopup, isArtwork2dModal, curateStyle } =
      this.state;
    const viewHeight = window.innerHeight;
    const artwork2dCarouselNav =
      document.querySelector(".artwork-2d-carousel__nav")?.clientHeight || 0;
    const artwork2dCarouselBodyRight =
      document.querySelector(".artwork-2d-carousel__body__left")
        ?.clientHeight || 0;

    if (isArtwork2dModal) {
      const artwork2dCarousel__nav = document.querySelector(
        ".artwork-2d-carousel__nav"
      )?.clientHeight;
      const artwork2dCarousel__header = document.querySelector(
        ".artwork-2d-carousel__header"
      )?.clientHeight;
      const artwork2dCarousel__showcase = document.querySelector(
        ".artwork-2d-carousel__showcase"
      )?.clientHeight;

      if (isMobileDevice() || window.innerWidth <= 990) {
        if (!!artwork2dCarousel__nav || !!artwork2dCarousel__header) {
          const artwork2dCarousel_showcase_height = `${artwork2dCarousel__showcase}px`;
          if(curateStyle?.height != artwork2dCarousel_showcase_height){
            this.setState(
              { curateStyle: { height: artwork2dCarousel_showcase_height } },
              () => {
                this.resizeCurateCanvas();
              }
            );
          }
        }
      } else {
        if (!!artwork2dCarousel__nav || !!artwork2dCarousel__header) {
          const newCalculatedHeight = `${ viewHeight - (artwork2dCarousel__nav + artwork2dCarousel__header)}px`;
          if(curateStyle?.height != newCalculatedHeight){
            this.setState(
              {
                curateStyle: {
                  height: newCalculatedHeight,
                },
              },
              () => {
                this.resizeCurateCanvas();
              }
            );
          }
        }
      }
    } else {
      if (audioDetailsPopup) {
        const artworkDetailsHeight =
          document.querySelector(".bottom-details")?.clientHeight || 0;
        const bottomGap = !isArtwork2dModal
          ? artworkDetailsHeight
          : artwork2dCarouselNav;

        if (isArtwork2dModal && window.innerWidth <= 990) {
          const newCalculatedHeight = `${viewHeight - (artwork2dCarouselBodyRight + bottomGap)}px`;
          if(curateStyle?.height != newCalculatedHeight) {
            this.setState(
              {
                curateStyle: {height: newCalculatedHeight}
              },
              () => {
                this.resizeCurateCanvas();
              }
            );
          }
        } else {
          if (isMobileDevice() || window.innerWidth <= 990) {
            const artworkDetailsPillHeight =
              document.getElementById("mobile-artwork-details-pill")
                ?.clientHeight || 0; // here - 16 is to cancel the negative margin top on pill
            const artworkDetailsTitleHeight =
              document.getElementById("curate-mobile-artwork-title")
                ?.clientHeight || 0;
            const artworkTitleTooltipHeight =
              (document.querySelector(".curate-mobile-artwork")?.clientHeight ||
                0) + 13; // here + 13 is to add the gap between bottom of the screen and the controls tooltip
            const totalHeight =
              viewHeight -
              (artworkTitleTooltipHeight +
                artworkDetailsTitleHeight +
                artworkDetailsPillHeight);
                if(curateStyle?.height != `${totalHeight}px`){
                  this.setState(
                    { curateStyle: { height: `${totalHeight}px` } },
                    () => {
                      this.resizeCurateCanvas();
                    }
                  );
                }
          } else {
            const newCalculatedHeight = `${viewHeight - bottomGap}px`;
            if(curateStyle?.height != newCalculatedHeight){
              this.setState(
                { curateStyle: { height: newCalculatedHeight } },
                () => {
                  this.resizeCurateCanvas();
                }
              );
            }
          }
        }

        this.resizeLoop();
      } else {
        const newCalculatedHeight = `${window.innerHeight}px`;
        if(curateStyle.height != newCalculatedHeight) {
          this.setState(
            { curateStyle: { height: `${window.innerHeight}px` } },
            () => {
              this.resizeLoop();
            }
          );
        }
      }
    }
  };

  handle3DClickFrom2D = (artworkId) => {
    const { selectedArtwork, selectedRoomId } = this.state;
    const self = this;
    this.setState({
      callBackOnRoomLoad: artworkId,
    });
    if (
      selectedArtwork.data.exhibitionSceneId !== selectedRoomId ||
      !this.state.scene
    ) {
      if (this.state.scene) {
        this.state.scene && this.state.scene.cleanup();
        this.removeCanvasElementFromDom();
        this.setState({ scene: null }, () => {
          self.handleRoomSelection(selectedArtwork.data.exhibitionSceneId);
        });
      } else {
        this.removeCanvasElementFromDom();
        this.handleRoomSelection(selectedArtwork.data.exhibitionSceneId);
      }
    } else {
      this.handleArtworkCardClick(artworkId, null);
    }
  };

  resetGuidedMode = () => {
    this.setState((prevState) => {
      return {
        restartTour: prevState.restartTour + 1,
      };
    });
  };

  handleExploreExhibition = () => {
    this.setState({ listview: false });
    this.toggleListViewInUrl(false);
    if (this.state.loadingProgress > 99) {
      this.closeDetails(false);
      this.setState({
        audioDetailsPopup: false,
        instructed: false,
      });
    }
    if (!this.state.scene) {
      this.fetch3dViewOnFirstTime();
    } else {
      this?.state?.scene?.pauseAudio(false);
    }
    this.listviewTimeoutRef.current = setTimeout(() => {
      this.setState({ listview: false });
      this.toggleListViewInUrl(false);
    }, 1000)
  };

  handleMovingToNextRoomFromArrows = () => {
    this.setState({ selectedTooltip: null, selectedTooltipLabel: "" });
    this.setState((prevState) => {
      return {
        shouldShowGuidedMode: true,
        restartTour: prevState.restartTour + 1,
      };
    });
    this.artworkDetailsRef?.current?.close();
    this.handleRoomSelection(this.state.roomChangingState.value);
  };

  removeCanvasElementFromDom = () => {
    const elements = document.getElementsByTagName("canvas");
    for (let element of elements) {
      if (element) {
        element.remove();
      }
    }
  };

  handleScroll = () => {
    const img = document.querySelector(".listview-background");
    if (document.querySelector(".curate-flexbox--listview")) {
      if (
        document.querySelector(".curate-flexbox--listview").scrollTop >
        window.innerHeight + 100
      ) {
        img?.classList?.add("fade");
        img?.classList?.remove("show");
      } else {
        img?.classList?.add("show");
        img?.classList?.remove("fade");
      }
    }
  };

  handleEnquireAside = (val) => {
    this.setState({ isEnquireAside: val });
  };

  handleViewExbitionMapClick = () => {
    if (this.state.viewMap) {
      this.onArtworkClick(false, true);
    } else {
      this.handleHeaderBtnClick(false, true, false, false, false);
      this.setState({ loadingScreen: false });
    }
  };

  getRoomDetails = () => {
    const { rooms, selectedRoomId } = this.state;
    const room = rooms?.filter(({ value }) => value === selectedRoomId);
    return room ? room[0] : {};
  };

  openMobileArtwork = (artworkId) => {
    this.setIsMobileArtworkExpanded(true);
    this.toggleCurateMobileArtwork(artworkId);
  };

  stopArtworkViewMode = () => {
    if (this.state.scene) {
      this.state.scene.stopViewMode();
    }
  };

  toggleCurateMobileArtwork = (
    artworkId: number | string | null | undefined
  ) => {
    if (this.state.instructed) {
      if (artworkId) {
        this.addArtworkIdToUrl(artworkId);
      } else {
        this.setState({
          isMobileArtwork: true,
        });
      }
    }
  };

  setTooltipStyle = (style) => {
    this.setState({ tooltipStyleTop: style });
  };

  prevArrowClick = () => {
    this.state.scene.guidedNavPrev();
    this.setState({ selectedTooltip: null, selectedTooltipLabel: "" });
  };

  nextArrowClick = () => {
    this.state.scene.guidedNavNext();
    this.setState({ selectedTooltip: null, selectedTooltipLabel: "" });
  };

  handleSidePanelAuthForm = (val) => {
    if (val) {
      this.setState({ sidePanel: val, sidePanelAuthForm: val });
    } else {
      this.setState({ sidePanel: !this.state.isPanelForAuth }, () => {
        const isForPanel = this.state.isPanelForAuth ? 1000 : 0;
        const timer = setTimeout(() => {
          this.setState({ sidePanelAuthForm: false, isPanelForAuth: false });
          clearTimeout(timer);
        }, isForPanel);
      });
    }
  };

  setToolTipBottom = (val) => {
    this.setState({ toolTipBottom: val });
  };

  putArtworkInDetailsModeFromMiniDetails = (artworkId) => {
    if (artworkId) {
      this.handleArtworkCardClick(artworkId, null);
    }
  };

  closeArtworkDetailsInMobile = () => {
    this.closeDetails(false);
  };

  toggleModalAuth = (val) => {
    this.setState({ isCurateAuthModal: val });
  };

  setConfirmationPopupVariant = (val = "") => {
    this.setState({ confirmationPopupVariant: val });
  };

  handleConfirmation = () => {
    const {
      handleExit,
      handleMovingToNextRoomFromArrows,
      setConfirmationPopupVariant,
    } = this;
    if (this.state.confirmationPopupVariant === "exitScreen") {
      handleExit();
    } else {
      this.setState({ isNotDefaultRoom: true });
      handleMovingToNextRoomFromArrows();
    }
    setConfirmationPopupVariant();
  };

  onHeaderRoomSwitch = (selectedRoomId) => {
    const { setConfirmationPopupVariant } = this;
    this.setState(
      {
        roomChangingState: {
          isChanging: true,
          value: selectedRoomId,
        },
      },
      () => {
        setConfirmationPopupVariant("switchScreen");
      }
    );
  };

  handleMenuClick = (val = null) => {
    this.setState({ sidePanel: val ? val : !this.state.sidePanel }, () => {
      this.resizeLoop();
    });
  };

  exhibitionLoadingScreenOnComplete = () => {
    const {isArtwork2dModal, defaultArtworkId} = this.state;
    if(!isArtwork2dModal){
      this.handleArtworkCardClick(defaultArtworkId);
      this.exhibitionLoadingCompleteTimeoutRef.current = setTimeout(() => {
        // if(isMobileDevice()) {
          this.setState({
            isLoadingScreen: false,
            isRoomLoading: false,
          })
        // }
      }, 1500)
    }
  }

  handleToggleArtworkDetailedView = (val: boolean) => {
    this.setState({ audioDetailsPopup: val })
  }

  handleIntroVideo = (val: boolean) => {
    this.setState({ isIntroVideoEnded: val })
  }

  toggleRoomsModal = (val: boolean, variant: 'room-switch' | 'exit-room' = 'room-switch') => {
    this.setState({ roomSwitchModalVariant: variant,  isRoomSwitchModal: val, sidePanel: false, audioDetailsPopup: false }, () => {
      this.artworkDetailsRef.current?.togglePlaying(false);
    });
  }

  openDetailedMode = () => {
    const { detailedArtworks } = this.state;
    const artworkId = detailedArtworks[0].data.id;
    this.handleArtworkClick(artworkId);
  }

  handleOrientationChange = () => {
    this.setState({ deviceOrientation: window.orientation }, () => {
      this.artworkDetailsRef?.current?.handleCloseArtworkDetails();
    })
  }

  public render() {
    const {
      isAudioPopupFull,
      isSidebar,
      loadingProgress,
      selectedArtwork,
      viewDesc,
      viewMap,
      viewEvent,
      enquire,
      audioDetailsPopup,
      isFavourite,
      loadingPageDetails,
      isRoomLoading,
      selectedRoom,
      showRoomsBox,
      audioPlaying,
      audioUrl,
      audioCurrentTime,
      audioTotalTime,
      isAnime,
      isActive,
      artworkPhotos,
      isArtwork2dModal,
      isArtworkModal,
      artworkModalSelectedIndex,
      listViewLoading,
      curateStyle,
      viewportWidth,
      isLoadingArtworks,
      toggleLogin,
      currentMobileArtworkState,
      exhibitionDetails,
      detailedArtworks,
      sidePanel,
      confirmationPopupVariant,
      isMenuVisible,
      is3dViewOpen,
      isArtworkDefaultStateActive,
      artworkMinimizedActive,
      isIntroVideoEnded,
      selectedRoomId,
      rooms,
      roomSwitchModalVariant,
      isRoomSwitchModal,
      shouldShowLoader,
      isLockedRoom,
      isCurateAudio,
      artworkAdditionalPhotos,
      listview,
      selectedSoftArtwork,
      softArtworkId,
      deviceOrientation
    } = this.state;
    const { isHidden } = this.props;
    const {
      setToggleLogin,
      setCurrentMobileArtworkState,
      handleExploreExhibition,
      toggleFavourite,
      handleArtworkClick,
      toggleModalAuth,
      setConfirmationPopupVariant,
      handleConfirmation,
      onHeaderRoomSwitch,
      handleMenuClick,
      handleHelp,
      exhibitionLoadingScreenOnComplete,
      handleIntroVideo,
      toggleRoomsModal,
      handleExit,
      openDetailedMode
    } = this;

    const exhibitionId = exhibitionDetails?.data?.id.toString();
    const exhibitionSlug = exhibitionDetails?.data?.slug;
    const isOldExhibition = exhibitionDetails?.data?.isOld;

    const dimensionsInCM = `${parseFloat(
      selectedArtwork && selectedArtwork.data.height
    ).toFixed(1)} x ${parseFloat(
      selectedArtwork && selectedArtwork.data.width
    ).toFixed(1)} x ${parseFloat(
      selectedArtwork && selectedArtwork.data.depth
    ).toFixed(1)}`;
    const dimensionsInIN = `${toInches(
      selectedArtwork && selectedArtwork.data.height
    )} x ${toInches(
      selectedArtwork && selectedArtwork.data.width
    )} x ${toInches(selectedArtwork && selectedArtwork.data.depth)}`;

    const isArtworkActiveIn3dFrom2dDetailedView =
      isArtwork2dModal && !this.state.listview && selectedArtwork && selectedArtwork?.data?.id;

    const collateralElement =
      exhibitionDetails?.data?.collateral?.data?.collateralElement || [];

    return (
      <>
        <div
          style={{
            position: "fixed",
            height: "100vh",
            ...(isHidden
              ? {
                  opacity: "0",
                  pointerEvents: "none",
                  position: "fixed",
                }
              : { opacity: "1", pointerEvents: "auto", position: "static" }),
          }}
        >
          {/* <ConversationIcon isInEvent /> */}
          {!!confirmationPopupVariant && (
            <ConfirmationPopup
              variant={confirmationPopupVariant}
              handleCancel={() => setConfirmationPopupVariant()}
              handleConfirm={handleConfirmation}
            />
          )}
          <div className="curate-wrapper">
            <Fragment>
              <div
                ref={this.curateRef}
                className={`curate ${
                  this.state.rooms && this.state.rooms.length > 0
                    ? "curate--with-rooms"
                    : ""
                } ${isSidebar || isArtwork2dModal ? "minimize-x" : ""} ${
                  isArtwork2dModal ? " curate--2d-modal" : ""
                }`}
                id="3d"
                style={curateStyle}
              >
                <div
                  className={`curate-grid ${
                    this.state.sidePanel ? "curate-grid--active" : ""
                  }`}
                >
                  <div
                    id={`curate-3d-wrapper`}
                    className={`${
                      this.state.isDetailedMode || this.state.audioDetailsPopup ? "curate-3d-wrapper--detailed-mode" : ""} 
                    ${this.state.isMobileArtworkExpanded ? 'curate-3d-wrapper--mobile-detailed-mode' : ''}
                    ${this.state.isLockedInDetailsMode ? "locked-mode" : ""}`}
                  >

                    <Fragment>
                      {shouldShowLoader && !is3dViewOpen && !listview && (this.state.isLoadingScreen || isRoomLoading) && (
                        <ExhibitionCurateLoading
                          isVideoEnabled={exhibitionDetails?.data?.isVideoEnabled}
                          imageUrl={constructImageUrl(exhibitionDetails?.data?.image, "1080")}
                          poster={constructImageUrl(exhibitionDetails?.data?.thumbnail, "480")}
                          posterUrl={this.state.introVideoPoster}
                          videoUrl={this.state.introVideoUrl}
                          progress={Math.floor(loadingProgress)}
                          galleryName={exhibitionDetails?.data.organisation?.data?.name}
                          timeLine={`${exhibitionDetails?.data?.startedAt || ""} ${(exhibitionDetails?.data?.endedAt && " - " + (loadingPageDetails && loadingPageDetails.endedAt)) || ""}`}
                          exhibitionName={exhibitionDetails?.data?.name}
                          description={(exhibitionDetails?.data?.description.trim() !== "") ? exhibitionDetails?.data?.description : ""}
                          setActiveHeader={() => this.setState({activeHeader: true})}
                          skipAnimation={() => this.state?.scene?.skipIntroGuidedAnimation()}
                          loadingOnComplete={exhibitionLoadingScreenOnComplete}
                          roomName={this.state.rooms?.length > 1 && selectedRoom && selectedRoom["label"]}
                          handleMoreClick={() => handleMenuClick(true)}
                          isSidePanel={this.state.sidePanel}
                          isIntroVideoEnded={isIntroVideoEnded}
                          setIsIntroVideoEnded={handleIntroVideo}
                          descriptionHtml={
                            exhibitionDetails?.data?.descriptionHtml
                          }
                          isRoomSwitchModal={isRoomSwitchModal}
                          setIntroAudioPlaying={() => this.setState({isIntoAudioPlaying: !this.state.isIntoAudioPlaying})}
                          setIsIntoAudioFound={(value: boolean) => this.setState({isIntoAudioFound: value})}
                          isIntoAudioFound={this.state.isIntoAudioFound}
                          ref={this.exhibitionLoadingScreenRef}
                        />
                      )}
                    </Fragment>
                    {/* Curate Header Start */}
                    {this.state.activeHeader && !is3dViewOpen && (
                      <CurateHeader
                        isListView={this.state.listview}
                        isLockedRoom={isLockedRoom}
                        isMobileArtworkExpanded={
                          this.state.isMobileArtworkExpanded
                        }
                        closeRoomsBox={this.handleInstrucationBoxClose}
                        rooms={this.state.rooms}
                        selectedRoom={this.state.selectedRoom}
                        handleRoomSelection={onHeaderRoomSwitch}
                        showRoomsBox={showRoomsBox}
                        isRoomLoading={isRoomLoading && loadingProgress < 100}
                        setIsDropdownSwitch={(val) =>
                          this.setState({ isDropdownSwitch: val })
                        }
                        handleExit={() => toggleRoomsModal(true, 'exit-room')}
                        inProgress={this.state.loadingProgress < 99}
                        onMenuClick={handleMenuClick}
                        isDetailedMode={
                          this.state.isDetailedMode ||
                          this.state.audioDetailsPopup
                        }
                        closeArtworkDetails={() => {this.closeDetails(false); this.artworkDetailsRef.current?.togglePlaying(false)}}
                        // closeArtworkDetails={(val) => this.setState({ audioDetailsPopup: false })}
                        sidePanel={this.state.sidePanel}
                        isMenuVisible={isMenuVisible}
                        openDetails={openDetailedMode}
                        isIntroVideoEnded={isIntroVideoEnded}
                        onRoomsClick={toggleRoomsModal}
                        isRoomSwitchModal={isRoomSwitchModal}
                        onAudioButtonClick={() => this.exhibitionLoadingScreenRef.current.toggleAudio()}
                        isIntoAudioPlaying={this.state.isIntoAudioPlaying}
                        isIntoAudioFound={this.state.isIntoAudioFound}
                      />
                    )}

                    {this.state.artworkLabel && !audioDetailsPopup && (
                      <p
                        className={`paragraph curate-artworkLabel ${
                          softArtworkId ? "active" : ""
                        }`}
                      >
                        {selectedSoftArtwork?.artist?.data?.name || ""},{" "}
                        <i>{selectedSoftArtwork?.title || ""}</i>{" "}
                        <span>{selectedSoftArtwork?.yearCreated}</span>
                      </p>
                    )}

                    {
                      !isArtworkActiveIn3dFrom2dDetailedView &&
                      !isArtwork2dModal && !listview &&
                      loadingProgress > 99 && (
                        <FadeDown delay="100ms">
                          <CurateMobileArtwork
                            key={deviceOrientation}
                            isSingleArtworkRoom={detailedArtworks?.length === 1}
                            isArt={exhibitionDetails?.data?.isArt}
                            sidePanel={sidePanel}
                            listview={listview}
                            isCurateAudio={isCurateAudio}
                            setArtworkPhotos={this.setArtworkPhotos}
                            artworkPhotos={artworkPhotos}
                            ref={this.artworkDetailsRef}
                            audioDetailsPopup={this.state.audioDetailsPopup}
                            onClick={(artworkId) =>
                              this.toggleCurateMobileArtwork(artworkId)
                            }
                            onClose={() => this.toggleCurateMobileArtwork(null)}
                            setTooltipStyle={this.setTooltipStyle}
                            medium={selectedArtwork && selectedArtwork?.data?.medium}
                            artworkCatalogueNumber={
                              selectedArtwork &&
                              selectedArtwork?.data.artworkCatalogueNumber
                            }
                            artistName={
                              selectedArtwork &&
                              selectedArtwork?.data?.artist &&
                              selectedArtwork.data.artist?.data?.name
                            }
                            artworkName={selectedArtwork && selectedArtwork?.data?.title}
                            yearCreated={
                              selectedArtwork && selectedArtwork?.data?.yearCreated
                            }
                            description={
                              selectedArtwork && selectedArtwork?.data?.description
                            }
                            galleryName={
                              selectedArtwork &&
                              selectedArtwork?.data?.organisation?.data?.name
                            }
                            price={selectedArtwork && selectedArtwork?.data?.price}
                            currency={selectedArtwork && selectedArtwork?.data?.currency}
                            photos={selectedArtwork && selectedArtwork?.data?.photos}
                            dimensionsInCM={dimensionsInCM}
                            dimensionsInIN={dimensionsInIN}
                            isMuseumType={this.isMuseumType()}
                            priceUnavailable={
                              (selectedArtwork &&
                                selectedArtwork?.data?.salesStatus ===
                                  "price on application") ||
                              selectedArtwork?.data?.salesStatus === "sold" ||
                              selectedArtwork?.data?.salesStatus === "reserved"
                            }
                            setArtworkModal={this.handleSelectedArtwork}
                            handleLeftClick={() => this.state?.scene?.guidedNavPrev()}
                            handleRightClick={() => this.state?.scene?.guidedNavNext()}
                            saveToCollection={() =>
                              this.toggleArtworkFavourite(selectedArtwork && selectedArtwork?.data?.id)
                            }
                            toggleFavourite={this.toggleFavourite}
                            closeDetails={() => this.closeDetails(false)}
                            isFavourite={this.getFavouriteStatus(selectedArtwork && selectedArtwork?.data?.id)}
                            exhibitiondId={getIdFromSlug(exhibitionId)}
                            isLoggedIn={this.props?.isLoggedIn}
                            prevArtwork={() => {
                              this.state?.scene?.guidedNavPrev();
                            }}
                            nextArtwork={() => {
                              this.state?.scene?.guidedNavNext();
                            }}
                            handleEnquire={() => this.handleEnquireAside(true)}
                            setTooltipBottom={this.setToolTipBottom}
                            isPlaying={audioPlaying}
                            artworkAudioUrl={selectedArtwork && selectedArtwork?.data?.artworkAudio}
                            currentTime={audioCurrentTime}
                            totalDuration={audioTotalTime}
                            isAnime={isAnime}
                            setPlaying={(val) => this.setState({ audioPlaying: val })}
                            setAudioUrl={(val) => {
                              // this.setState({ audioUrl: val });
                            }}
                            setCurrentTime={(val) =>
                              this.setState({ audioCurrentTime: val })
                            }
                            setTotalTime={(val) => this.setState({ audioTotalTime: val })}
                            setIsAnime={(val) => this.setState({ isAnime: val })}
                            exhibitionId={
                              this.state.exhibitionDetails?.data?.uuid ||
                              this.state.exhibitionDetails?.data?.slug
                            }
                            isMobileArtwork={isArtworkDefaultStateActive && !audioDetailsPopup && !isRoomSwitchModal}
                            // TODO: This might cause the trouble with re-renderings ....
                            // artworkId={(isMobileDevice() || window.innerWidth) <= 990 && selectedArtwork && selectedArtwork?.data?.id}
                            artworkId={selectedArtwork && selectedArtwork?.data?.id}
                            handleLogin={() => {
                              this.setState({ toggleLogin: true });
                            }}
                            excludedTax={
                              selectedArtwork && selectedArtwork?.data?.excludedTax
                            }
                            isNotMuseum={isTypeMuseum(
                              selectedArtwork?.data?.organisation?.data?.type
                            )}
                            isForSale={getArtworkStateStatus(
                              selectedArtwork?.data?.salesStatus,
                              selectedArtwork?.data?.isForSale
                            )}
                            isActive={this.state.isMobileArtworkExpanded}
                            setIsActive={this.setIsMobileArtworkExpanded}
                            isLockedInDetailsMode={this.state.isLockedInDetailsMode}
                            handleAllTooltips={this.handleAllTooltips}
                            currentState={currentMobileArtworkState}
                            setCurrentState={setCurrentMobileArtworkState}
                            putArtworkInDetailsMode={
                              this.putArtworkInDetailsModeFromMiniDetails
                            }
                            closeArtworkDetailsInMobile={this.closeArtworkDetailsInMobile}
                            onSeenArtworkId={this.state.onSeenArtworkId}
                            instructed={this.state.instructed}
                            toggleModalAuth={toggleModalAuth}
                            selectedTooltip={this.state.selectedTooltip}
                            resetTooltips={() =>
                              this.setState({
                                selectedTooltip: null,
                                selectedTooltipLabel: "",
                              })
                            }
                            activeMinimizedProp={artworkMinimizedActive}
                            handleActiveMinimizedProp={(val: boolean) => this.setState({ artworkMinimizedActive: val })}
                            handleToggleArtworkDetailedView={this.handleToggleArtworkDetailedView}
                            resizeCurateCanvas={this.resizeAll}
                            onCloseDesktopDetails={() => this.setState({ audioDetailsPopup: false })}
                            viewportWidth={viewportWidth}
                            handleLoginForDesktop={() => {
                                this.handleSidePanelAuthForm(true)
                                this.setState({ isPanelForAuth: true })
                              }
                            }
                            handleArtworkDescriptionForDesktop={(val, height) => {
                              this.setState({
                                isArtworkDescription: val,
                                artworkDescHeight: height,
                              });
                              this.handleDescription();
                            }}
                            getCurateHeight={this.getCurateHeight}
                          />
                        </FadeDown>
                    )}

                  </div>
                  <div
                    className={`side-panel-curate-wrapper ${
                      sidePanel ? "side-panel-curate-wrapper--active" : ""
                    }`}
                  >
                    <SidePanel
                      isArt={exhibitionDetails?.data?.isArt}
                      sidePanel={sidePanel}
                      isLoggedIn={this.props.isLoggedIn}
                      exhibitionSlug={exhibitionSlug}
                      id={exhibitionId}
                      galleryName={
                        loadingPageDetails &&
                        loadingPageDetails.organisation &&
                        loadingPageDetails.organisation.data &&
                        loadingPageDetails.organisation.data.name
                      }
                      aboutTitle={loadingPageDetails && loadingPageDetails.name}
                      imgUrl={constructImageUrl(
                        exhibitionDetails?.data?.image,
                        1080
                      )}
                      state={exhibitionDetails}
                      exhibitionuuId={
                        this.props.params.id || loadingPageDetails?.uuid
                      }
                      collateralDetails={exhibitionDetails?.data?.collateral}
                      timeline={`${
                        (loadingPageDetails && loadingPageDetails.startedAt) ||
                        ""
                      } - ${loadingPageDetails && loadingPageDetails.endedAt}`}
                      artworks={detailedArtworks}
                      handleArtworkClick={handleArtworkClick}
                      onClose={() =>
                        this.setState({ sidePanel: false }, () => {
                          this.resizeLoop();
                        })
                      }
                      handleViewListClick={() => {
                        this.state?.scene?.pauseAudio(true);
                        this.toggleListViewInUrl(true, true);
                      }}
                      isOldExhibition={isOldExhibition}
                      roomId={this.state.selectedRoomId}
                      sidePanelAuthForm={this.state.sidePanelAuthForm}
                      setSidePanelAuthForm={this.handleSidePanelAuthForm}
                      toggleModalAuth={this.toggleModalAuth}
                      toggleFavourite={toggleFavourite}
                      isFavourite={isFavourite}
                      handleHelpClick={this.handleHelp}
                      isDateVisible={exhibitionDetails?.data?.isDateVisible}
                      isArtistVisible={exhibitionDetails?.data?.isArtistVisible}
                      isCurateLoading={loadingProgress < 100}
                      onAudioButtonClick={() => this.exhibitionLoadingScreenRef.current.toggleAudio()}
                      isIntoAudioPlaying={this.state.isIntoAudioPlaying}
                    />
                  </div>
                </div>

                {(is3dViewOpen) && loadingProgress < 100 && (
                  <RoomLoader
                    border={this.state.isSidebar}
                    isArtwork2dModal={isArtwork2dModal}
                    progress={loadingProgress}
                    title={loadingPageDetails && loadingPageDetails.name}
                    roomName={selectedRoom && selectedRoom["label"]}
                    galleryName={
                      loadingPageDetails &&
                      loadingPageDetails.organisation &&
                      loadingPageDetails.organisation.data &&
                      loadingPageDetails.organisation.data.name
                    }
                    timeLine={`${
                      (loadingPageDetails && loadingPageDetails.startedAt) || ""
                    } - ${loadingPageDetails && loadingPageDetails.endedAt}`}
                  />
                )}
              </div>

              {this.state.listview && !is3dViewOpen && "curate-flexbox--listview" && (
                <div className="listview-bg-wrapper"></div>
              )}

              {
                // I have to refactor it and we have to replace this with the sidePanel one.
                this.state.listview && (
                  <div
                    onScroll={this.handleScroll}
                    className={classNames(
                      "curate-flexbox",
                      { "curate-flexbox--3d-view-open": is3dViewOpen },
                      { "curate-flexbox--enquire": enquire },
                      { "curate-flexbox--listview": this.state.listview },
                      { "active": this.state.isSidebar || this.state.listview },
                      { "un-active": !(this.state.isSidebar || this.state.listview) },
                      { "about-box": this.state.viewDesc },
                      { "open-artwork-details": this.state.audioDetailsPopup }
                    )}
                  >
                    <div className="overlay-box" />

                    {this.state.listview && listViewLoading && (
                      <div className="listview-loader">
                        <Spinner />
                      </div>
                    )}
                    {this.state.listview &&
                      !listViewLoading &&
                      exhibitionDetails && (
                        <Listview
                          is3dViewOpen={is3dViewOpen}
                          video={exhibitionDetails?.data?.video}
                          image={exhibitionDetails?.data?.image}
                          thumbnail={exhibitionDetails?.data?.thumbnail}
                          about={exhibitionDetails?.data?.about}
                          descriptionHtml={
                            exhibitionDetails?.data?.descriptionHtml
                          }
                          description={exhibitionDetails?.data?.description}
                          galleryName={
                            exhibitionDetails?.data?.organisation?.data?.name ||
                            ""
                          }
                          exhibitionName={exhibitionDetails?.data?.name || ""}
                          handleExploreClick={handleExploreExhibition}
                          exhibitionId={exhibitionDetails?.data?.id}
                          exhibitionSlug={
                            exhibitionDetails?.data?.exhibitionSlug
                          }
                          startedAt={exhibitionDetails?.data?.startedAt}
                          endedAt={exhibitionDetails?.data?.endedAt}
                          collateralElement={collateralElement}
                          toggleFavourite={toggleFavourite}
                          isFavourite={isFavourite}
                          setToggleLogin={setToggleLogin}
                          isLoggedIn={this.props.isLoggedIn}
                          artworks={detailedArtworks}
                          isMuseumType={this.isMuseumType()}
                          handleArtworkClick={handleArtworkClick}
                          setIs3dViewOpen={(val) =>
                            this.setState({ is3dViewOpen: val })
                          }
                          isVideoEnabled={exhibitionDetails.data.isVideoEnabled}
                          uuid={this.props.params.id}
                          isArtistVisible={
                            exhibitionDetails?.data?.isArtistVisible
                          }
                          isDateVisible={exhibitionDetails?.data?.isDateVisible}
                        />
                      )}
                    {(this.state.viewMap ||
                      (this.state.listview && !listViewLoading)) &&
                      this.state.exhibitionDetails && (
                        <CurateSidebarDark
                          isOldExhibition={isOldExhibition}
                          onClickArtwork={this.handleArtworkCardClick}
                          roomId={this.state.selectedRoomId}
                          isActiveBox={audioDetailsPopup}
                          setIsLoading={() =>
                            this.setState({ listViewLoading: false })
                          }
                          isLoadingArtworks={isLoadingArtworks}
                          onClose={this.onArtworkClick}
                          artworks={detailedArtworks}
                          setIs3dViewOpen={(val) =>
                            this.setState({ is3dViewOpen: val })
                          }
                          isListView={this.state.listview}
                          setArtwork2dModal={this.setArtwork2dModal}
                          isArtwork2dModal={isArtwork2dModal}
                          state={
                            this.props.location.state &&
                            this.props.location.state.details
                              ? this.props.location.state.details
                              : this.state.exhibitionDetails
                          }
                          exhibitionId={exhibitionId}
                          exhibitionSlug={exhibitionSlug}
                          handleArtworkClick={handleArtworkClick}
                          uuid={this.props.params.id}
                          loadingScreen={this.state.loadingScreen}
                          isPreview={this.props.isPreview}
                          isArtistVisible={
                            this.state.exhibitionDetails?.data?.isArtistVisible
                          }
                        />
                      )}
                    {this.state.listview && <ListviewFooter />}
                  </div>
                )
              }
            </Fragment>

            {audioUrl && audioUrl !== "" && (
              <Audio
                setTotalTime={(val) => this.setState({ audioTotalTime: val })}
                setCurrentTime={(val) =>
                  this.setState({ audioCurrentTime: val })
                }
                url={audioUrl}
                play={audioPlaying}
              />
            )}
          </div>
          <div id="garbageVideo" className={"garbage-audio-video"}>
            <video
              crossOrigin="anonymous"
              src={""}
              id={"hiddenVideoElement"}
              style={{ opacity: 0, zIndex: -3, width: "0px", height: "0px" }}
            ></video>
          </div>
          <div id="ambientAudio" className={"garbage-audio-video"}></div>
        </div>

        {isArtwork2dModal && (
          <>
            {this.state.detailedArtworks?.length > 0 ? (
              <Artwork2dCarousel
                isArt={exhibitionDetails?.data?.isArt}
                handleAuthModal={(val) =>
                  this.setState({ isCurateAuthModal: val })
                }
                handleClickEnquire={() => {
                  this.setState({
                    enquire: true,
                  });
                }}
                isMuseumType={this.isMuseumType()}
                isOldExhibition={isOldExhibition}
                progress={this.state.loadingProgress}
                exhibitionSlug={exhibitionSlug}
                isListView={listview}
                dimensionsInCM={`${dimensionsInCM} cm`}
                dimensionsInIN={`${dimensionsInIN} in`}
                exhibitionId={exhibitionId}
                artworks={this.state.detailedArtworks.filter(
                  ({ data }) => !data.isCollateral
                )}
                setArtwork2dModal={(val) => {
                  this.setArtwork2dModal(val);
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  isArtwork2dModal ? "" : this.setState({ listview: true });
                }}
                selectedArtwork={this.state.selectedArtwork}
                artworkMedium={selectedArtwork && selectedArtwork?.data?.medium}
                isArtworkActiveIn3dFrom2dDetailedView={
                  isArtworkActiveIn3dFrom2dDetailedView
                }
                on3dButtonClick={this.handle3DClickFrom2D}
                handleClose={() => {
                  this.setState(
                    {
                      isArtwork2dModal: false,
                      artwork2dSelected: null,
                      listview: true,
                      is3dViewOpen: false,
                      audioDetailsPopup: false,
                    },
                    () => {
                      this.addArtworkIdToUrl(null);
                      this.handleRemoveDetails();
                    }
                  );
                  this.closeDetails(false);
                }}
                shareUrl={getShareURL("artwork", selectedArtwork?.data?.id)}
                isFavourite={this.getFavouriteStatus(selectedArtwork && selectedArtwork?.data?.id)}
                isSidebar={false}
                onClickSlider={() => this.setState({ listview: true })}
                handleSidePanelAuthForm={this.handleSidePanelAuthForm}
                onFavourite={() =>
                  this.toggleArtworkFavourite(selectedArtwork?.data?.id)
                }
                chainLink={this.getCurateArtworkAddress()}
                exhibitionsId={exhibitionId}
                galleryName={
                  selectedArtwork &&
                  selectedArtwork?.data &&
                  selectedArtwork?.data?.organisation &&
                  selectedArtwork?.data?.organisation.data.name
                }
                exhibitiondId={getIdFromSlug(exhibitionId)}
                secondary
                isArtwork2dModal
                is3dViewOpen={this.state.is3dViewOpen}
                setIs3dViewOpen={(val) => this.setState({ is3dViewOpen: val })}
                handleSelectedArtwork={this.handleSelectedArtwork}
                isArtworkModal={isArtworkModal}
                handleArtworkClick={this.handleArtworkClick}
                setToggleLogin={setToggleLogin}
                toggleLogin={toggleLogin}
                isLoggedIn={this.props.isLoggedIn}
                excludedTax={selectedArtwork?.data?.excludedTax}
                photos={selectedArtwork && selectedArtwork.data?.photos}
              />
            ) : (
              <div className="artwork-2d-carousel-modal-loader">
                <Spinner />
              </div>
            )}
          </>
        )}

        {isArtworkModal && (
          <ArtworkCarousel
            artworkPhotos={artworkAdditionalPhotos}
            dimensionsInCM={`${dimensionsInCM} cm`}
            dimensionsInIN={`${dimensionsInIN} in`}
            artworkMedium={selectedArtwork && selectedArtwork?.data?.medium}
            handleClose={() => this.setArtworkModal(false)}
            isFavourite={this.getFavouriteStatus(selectedArtwork && selectedArtwork?.data?.id)}
            isSidebar={false}
            onFavourite={() =>
              this.props.isLoggedIn
                ? this.toggleArtworkFavourite(selectedArtwork && selectedArtwork?.data?.id)
                : toggleModalAuth(true)
            }
            selectedIndex={artworkModalSelectedIndex}
            setArtwork2dModal={this.setArtwork2dModal}
            shareUrl={getShareURL("artwork", selectedArtwork && selectedArtwork?.data?.id)}
          />
        )}

        {toggleLogin && !this.props.isLoggedIn && (
          <AuthenticationForm
            className="curate-auth-form"
            type="login"
            isLoggedIn={this.props.isLoggedIn}
            onCloseForm={() => setToggleLogin(false)}
            toggleLogin={toggleLogin}
            setToggleLogin={setToggleLogin}
            fromExhLoading={true}
            artworkDetailsEnquire
          />
        )}

        {this.state.isEnquireAside && (
          <SlideOut
            ref={this.enquireModalRef}
            onClose={() => this.handleEnquireAside(false)}
          >
            <CurateEnquireSideBar
              activeArtworkId={selectedArtwork && selectedArtwork?.data?.id}
              exhibitionsId={exhibitionId}
              galleryName={loadingPageDetails?.organisation?.data?.name}
              handleAuthModal={(val) =>
                this.setState({ isCurateAuthModal: val })
              }
              handleCancel={() => this.enquireModalRef.current.close()}
            />
          </SlideOut>
        )}

        {this.state.isCurateAuthModal && (
          <SlideOut
            childClassName="stick-bottom"
            ref={this.authModalRef}
            onClose={() => this.toggleModalAuth(false)}
          >
            <AuthModalForm
              className="curate-auth-form"
              type="login"
              isLoggedIn={this.props.isLoggedIn}
              onCloseForm={() => setToggleLogin(false)}
              toggleLogin={toggleLogin}
              setToggleLogin={setToggleLogin}
              fromExhLoading={true}
              handleCancel={() => this.authModalRef.current.close()}
              isCurateAuthModal={this.state.isCurateAuthModal}
            />
          </SlideOut>
        )}

        {
          this.state.isRoomSwitchModal &&
          <RoomSwitchModal
            selectedRoomId={selectedRoomId}
            onClose={() => toggleRoomsModal(false)}
            rooms={rooms}
            handleExitExhibition={handleExit}
            handleRoomSelection={(val) => this.handleRoomSelection(val)}
            roomSwitchModalVariant={roomSwitchModalVariant}
          />
        }
      </>
    );
  }
}


// Higher-order component to inject route params and query params
const withParams = (WrappedComponent) => {
  return (props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const getLocationSearchIds = (search) => {
      const params = new URLSearchParams(search);
      return {
        artworkId: params.get('artworkId'),
        listview: params.get('listview'),
        sceneId: params.get('sceneId'),
        eliminateSignedUrl: params.get('eliminateSignedUrl'),
      };
    };

    const locationSearchIds = getLocationSearchIds(searchParams.toString());

    return <WrappedComponent {...props} params={params} queryParams={locationSearchIds} location={location} navigate={navigate} />;
  };
};

// @ts-ignore
export default withParams(Curate);

