// @ts-nocheck
// import * as Sentry from "@sentry/react";
import React, {lazy, Suspense, useEffect, useState} from 'react';
import { Routes, useLocation, useNavigate, Route, Navigate, BrowserRouter, useParams } from "react-router-dom";
// import {Redirect, Route, Switch} from "react-router";
// import {BrowserRouter, useHistory} from 'react-router-dom'
import { createBrowserHistory } from 'history';
import Header from "../../components/Header";
import {ITypeGlobal, ITypeRenderRoute} from "./types"
import Footer from "../../components/Footer";
import LoadingPage from "../../components/LoadingPage";
import ExhibitionDetails from "../Exhibitions/Details";
import Login from '../Login';
import SignUp from '../SignUp';
import Verify from '../Verify';
import ResetPage from '../Reset';
import ForgotPassword from '../ForgotPassword';
// import Conversation from "../Conversation";
import SearchPage from "../Search";
import ArtworkDetailsContainer from "../ArtworkDetails";
// import MyProvider from '../../containers/NewChat/ContextAPI/MyProvider'
import ExhibitionsPage from "../Exhibitions";
import GalleriesPage from "../Galleries";
import MuseumPage from "../Museum";
import GalleryDetails from "../Galleries/Details";
// import DiscoverDetails from "../DiscoverLayout/Details";
import DiscoverDetails from "../Discover/Details";
import ArtistDetails from "../ArtistDetails";
import ArticleDetails from "../ArticleDetails";
import Favourites from "../Favourites";
import CuratePage from "../Curate";
import CollaborationsPage from "../Collectives";
import CollectiveDetails from "../Collectives/Details";
// import DiscoverPage from "../DiscoverLayout";
import DiscoverPage from "../Discover";
import Notification from "../../components/Notification";
import CookiePopup from "../../components/CookiePopup";
import NotFound from "../../components/NotFound";
import CodeVerification from "../../components/CodeVerification";
import PairVr from "../../components/PairVr";
import logger from '../../utils/logger';
import HeadTags from "../../components/Helmet/HeadTags";
import NewsLetter from '../../components/NewsLatter/NewsLetter';
// import Dragger from '../../components/Dragger';
import NewsLetterPopup from '../Exhibitions/Details/NewsLetter/NewsLetterPopup';
import Curator from '../Curator/Details';
import OrganisationDetails from '../Organisations/Details';
import ArtworkPreview from '../ArtworkPreview';
import './global.scss';
import rudderStackEvents from "../../services/rudderstack-events";
import rudderstackEvents from "../../services/rudderstack-events";
import { isMobileDevice } from '../../utils';
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const HomePage = lazy(() => import ("../Home"));
const ProfilePage = lazy(() => import ("../Profile"));
const InvitePage = lazy(() => import ("../Invite"));

let prevUrl = window.location.pathname + window.location.search;
const FOOTER_DELAY = 8000; // for footer delay

const CurateDummy= () => {
    return <div></div>
}

const RenderRoutes = React.memo(({ isLoggedIn, setExhibitionLoading, redirectPath, setCurateHistoryProps }) => {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Routes>
                {/* ROUTES: FOR ALL CONDITIONS */}
                <Route path="/exhibitions" element={<ExhibitionsPage setCurateHistoryProps={setCurateHistoryProps} />} />
                <Route path="/collaborations/:type" element={<CollaborationsPage />} />
                <Route path="/galleries" element={<GalleriesPage />} />
                <Route path="/museums-&-public-galleries" element={<MuseumPage />} />
                <Route path="/discover" element={<DiscoverPage />} />
                <Route path="/discover/preview" element={<DiscoverPage preview />} />
                <Route path="/discover/:id" element={<DiscoverDetails />} />
                <Route path="/" element={<Navigate to="/discover" />} />
                <Route path="/invite/:inviteId" element={<InvitePage />} />
                <Route path="/reset/:resetId" element={<ResetPage />} />
                <Route
                    path="/exhibitions/:id/details"
                    element={<ExhibitionDetails setExhibitionLoading={setExhibitionLoading} />}
                />
                <Route path="/galleries/:id" element={<GalleryDetails />} />
                <Route path="/galleries/preview/:id" element={<GalleryDetails preview />} />
                <Route path="/organisations/:id" element={<OrganisationDetails />} />
                <Route path="/curators/:id" element={<Curator />} />
                <Route path="/curators/preview/:id" element={<Curator preview />} />
                <Route path="/collateral-content/:id" element={<ArticleDetails />} />
                <Route path="/artists/:id" element={<ArtistDetails />} />
                <Route path="/artists/preview/:id" element={<ArtistDetails preview />} />
                <Route path="/collectives/:id" element={<CollectiveDetails />} />
                <Route path="/404" element={<NotFound />} />
                <Route path="/code-verification" element={<CodeVerification />} />
                <Route path="/forgot-password" element={<ForgotPassword isForgotPage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/artworks/preview/:id" element={<ArtworkPreview isLoggedIn={isLoggedIn} />} />

                {/* ROUTES: NOT LOGGED IN */}
                {!isLoggedIn && <Route path="/login" element={<Login />} />}
                {!isLoggedIn && <Route path="/signup" element={<SignUp isLoggedIn={isLoggedIn} />} />}
                {!isLoggedIn && <Route path="/verify" element={<Verify />} />}

                {/* ROUTES: ONLY LOGGED IN */}
                {isLoggedIn && <Route path="/profile" element={<ProfilePage />} />}
                {isLoggedIn && <Route path="/favourites" element={<Favourites />} />}
                <Route path="/pairvr" element={<PairVr isLoggedIn={isLoggedIn} />} />
                <Route
                    path="/exhibitions/:id"
                    element={
                        // <CurateDummy setCurateHistoryProps={setCurateHistoryProps} />
                        <CuratePage />
                    }
                />
                <Route
                    path="/exhibitions/preview/:id"
                    element={
                        <CuratePage />
                    }
                />

                {/* REDIRECT BASED ON LOGIN STATUS */}
                {isLoggedIn ? (
                    <Route path="*" element={<Navigate to={redirectPath || '/exhibitions'} />} />
                ) : (
                    <Route path="*" element={<Navigate to="/" />} />
                )}
            </Routes>
        </Suspense>
    );
});

const Root = ({
                  isLoggedIn,
                  setCurrentConversation,
                  currentConversation,
                  enquireMessage,
                  profile,
                  redirectPath,
                  setChat,
                  setEnquireMessage,
                  exhibitionsFirstLoad,
                  isConversation,
                  vH,
                  isFirstLoad,
                  setVH,
                  artworkId,
                  setArtworkId,
                  exhibitionId,
                  setExhibitionId,
                  setIsError,
                  errorMessage,
                  isError,
                  isMobileForm
              }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [curateHistoryProps, setCurateHistoryProps] = useState(null);
    const [isHeader, setHeader] = useState(null);
    const [isHome, setHome] = useState(null);
    const [isProfile, setProfile] = useState(null);
    const [routeName, setRouteName] = useState(location?.pathname);
    const [isAuthenticationPage, setAuthenticatingPage] = useState(null);
    const [isSearchPage, setIsSearchPage] = useState(false);
    const [isArtworksPage, setIsArtworksPage] = useState(false);
    const [loginPath, setLoginPath] = useState('/login');
    const [mokeDelay, setMokeDelay] = useState(false);
    const [isFlex, setFlex] = useState(false);
    const [mokeHeadersDelay, setMokeHeadersDelay] = useState(true);
    const [vHeight, setViewHeight] = useState(0);
    const [headerDelay, setHeaderDelay] = useState(8000); // for header delay
    const [isCurate, setCurate] = useState(false); // for header delay
    const [cookieAccepted, setCookieAccepted] = useState(false); // for header delay
    const [isNewsLetterSubmited, setIsNewsLetterSubmited] = useState(false); // for header delay
    const [showNewsLetter, setShowNewsLetter] = useState(false); // for header delay
    const [exhibitionLoading, setExhibitionLoading] = useState(false); // for header delay
    const [showDetailPageNewsLatter, setShowDetailPageNewsLatter] = useState(false)
    const [artworkPreviewPage, setArtworkPreviewPage] = useState(false)
    const [isCollaborationsUntitled, setIsCollaborationsUntitled] = useState(false);
    const { setPathPageView, setIdentity } = useTrackingCode();

    //@ts-ignore
    useEffect(() => {
        rudderStackEvents.setPagePath(location.href);
        setPathPageView(location.pathname);
        //@ts-ignore
        if (!window.gtag) return;
        //@ts-ignore
        if (!window.prevUrl) {
            //@ts-ignore
            window.prevUrl = location.pathname
        }
        //@ts-ignore
        gtag && gtag('config', 'G-3JDTGTTV3Q', {
            page_title: location.pathname + "/test",
            page_path: location.pathname + "/test"
        });
        document.addEventListener("click", (e) => {
            //@ts-ignore
            let tagName = e.target.tagName;
            //@ts-ignore
            let targetUrl = location.pathname + location.search;
            if (!["a", "button"].includes(tagName.toLowerCase()) || prevUrl === targetUrl) return;
            //@ts-ignore
            gtag('event', `Event-Tag-Name ${tagName.toLowerCase()} (<${tagName.toLowerCase()}>)`, {
                'event_category': location.pathname.split('/')[1] ?? "Category",
                'event_label': JSON.stringify({
                    currentUrl: prevUrl,
                    targetUrl: location.pathname + location.search
                }),
                'value': 1
            });
            prevUrl = location.pathname + location.search
        })

    }, [location.pathname])

    useEffect(() => {
        if (isFirstLoad) {
            setHeaderDelay(0);
            logger.debug('isFirstLoad in global', isFirstLoad, headerDelay);
        } else {
            setHeaderDelay(8000);
            logger.debug('isFirstLoad in global', isFirstLoad, headerDelay);
        }
    }, [isFirstLoad]);

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    useEffect(() => {
        if(profile && profile.id) {
            rudderstackEvents.setUserId(profile.id)
            setIdentity(profile?.email)
        }
    }, [profile])

    function checkCookie() {
        var user = getCookie("isCookieAccepted");
        if (user === "1") {
            setCookieAccepted(true);
        } else {
            setCookieAccepted(false);
        }
    }
    function checkNewLetterInCookie() {
        var user = getCookie("isNewsLetterSubmited");
        setIsNewsLetterSubmited(user === "1");
        setShowNewsLetter(user !== "1");
    }
    function checkDetailPageNewLetterInCookie() {
        var user = getCookie("isNewsLetterSubmited");
        setIsNewsLetterSubmited(user === "1");
        setShowDetailPageNewsLatter(user !== "1");
    }

    useEffect(() => {
        if (cookieAccepted === true) {
            logger.debug('profile => ', profile);
            setCookie("isCookieAccepted", "1", 365);
        }
    }, [cookieAccepted])
    useEffect(() => {
        if(isNewsLetterSubmited){
            setCookie("isNewsLetterSubmited", "1", 365);
        }
    }, [isNewsLetterSubmited])

    useEffect(() => {
        checkCookie();
        checkNewLetterInCookie();
    }, [])


    // :TODO This is all about Loader and all that previous animation, ( NEED A REVIEW )
    // useEffect(() => {
    //     const localStorageVal = window.localStorage.getItem('isFirstLoad');
    //     if(localStorageVal === 'true'){
    //         console.log('isFirstLoad in Global', localStorageVal === 'true');
    //         // setIsLoaded(true);
    //         // setmockDelay(true);
    //         // setFirstLoadGlobal(true);
    //         // setIsFirstLoad(true);
    //     }
    //     else {
    //         // setIsFirstLoad(false);
    //         // setFirstLoadGlobal(true);
    //     }
    // }, []);

    useEffect(() => {
        handleHeader(location.pathname);
        const body = document.querySelector('.body-height');
        logger.debug('body.clientHeight => ', body.clientHeight);
        setViewHeight(body.clientHeight - ((26 / 100) * body.clientHeight));
    }, [vH]);

    useEffect(() => {
        if (isConversation) {
            setFlex(true);
        } else {
            setTimeout(() => {
                setFlex(false);
            }, 400);
        }
    }, [isConversation]);

    useEffect(() => {
        setTimeout(() => {
            setIsError(false, '');
        }, 5000)
    }, [errorMessage, isError])

    useEffect(() => {
        const body = document.querySelector('.body-height');
        body && setVH(body.clientHeight);

    }, []);

    const togglePinch = () => {
        // console.log('togglePinch = ', location.pathname, document.getElementsByTagName('html')[0].style['touch-action'], document.getElementsByTagName('html')[0].style['touch-action'])
        if(location.pathname.includes("exhibitions/")) {
            document.getElementsByTagName('html')[0].style['touch-action'] = "none"
            document.getElementsByTagName('body')[0].style['touch-action'] = "none"
        }
        else {
            document.getElementsByTagName('html')[0].style['touch-action'] = "inherit"
            document.getElementsByTagName('body')[0].style['touch-action'] = "inherit"
        }
    }

    useEffect(() => {
        // Call togglePinch on initial render
        togglePinch();
    
        // Handle route changes
        const handleRouteChange = () => {
          togglePinch();
          setRouteName(location.pathname);
          handleHeader(location.pathname);
          
          if (!location.pathname.includes("details") && !location.pathname.includes("paularego") && !location.pathname.includes("exhibitions/")) {
            setCurateHistoryProps(null);
          }
          
          if (location.pathname === '/') {
            setLoginPath('/login');
          } else {
            setLoginPath(`/login?${location.pathname}`);
          }
        };
    
        // Call handleRouteChange initially and on every location change
        handleRouteChange();
    
        // No need to manually listen for changes; useEffect will run on location changes
      }, [location]);

    // :TODO This is same all about Loader and all that previous animation, ( NEED A REVIEW )
    // useEffect(() => {
    //     console.log('exhibitionsFirstLoad => in global ', exhibitionsFirstLoad);
    //
    //     if(exhibitionsFirstLoad === false){
    //         setMokeHeadersDelay(false);
    //         setTimeout(() => {
    //             setMokeHeadersDelay(true);
    //             setHeaderDelay(0);
    //         }, headerDelay);
    //         setTimeout(() => {
    //             setMokeDelay(true);
    //         }, FOOTER_DELAY);
    //     }
    // }, [exhibitionsFirstLoad, history]);

    useEffect(() => {
        if(!location.pathname.includes('/artists/')) {
            const body = document.querySelector('body');
            const html = document.querySelector('html');
            const header = document.querySelector('header');
            if (!!artworkId) {
                body?.classList.add('scrollbar-hidden')
                header?.classList.add('fixed-header')
                html?.style.overflowY = 'unset';
            } else {
                if (header!==null&&header.classList.contains('fixed-header')){
                    header.classList.remove('fixed-header')
                }
                body.classList.remove('scrollbar-hidden')
            }
        }
    }, [artworkId])
    useEffect(()=>{
        //console.log('path => ', history.location.pathname.includes('/exhibitions') , !history.location.pathname.includes('/details'), history.location.pathname.includes('/exhibitions') && !history.location.pathname.includes('/details'));
        const footer = document.querySelector('footer');
        const html = document.querySelector('html');
        if (location.pathname.includes('/paularego')){
            footer.style.display='none'
            html.style.overflowY = 'hidden';
        }
    })
    const handleHeader = (pathname) => {
        if ( pathname !== "/exhibitions" && pathname !== "/exhibitions/" && ( pathname.includes('/exhibitions') && !pathname.includes('/details') ) || pathname.includes('invite') ||pathname.includes('/paularego')) {
            setHeader(false);
        } else {
            setHeader(true);
        }
        if (pathname.includes('login') || pathname.includes('/signup') || pathname === '/verify' || pathname === '/forgot-password' || pathname.includes('/reset')) {
            setHome(true);
        } else {
            setHome(false);
        }
        if (pathname.includes('/artworks/') && (pathname.includes('/artworks/preview/') === false)) {
            setIsArtworksPage(true)
        } else {
            setIsArtworksPage(false);
        }
        if(pathname.includes('/artworks/preview/')){
            setArtworkPreviewPage(true);
        } else {
            setArtworkPreviewPage(false);
        }
        if (pathname === '/profile') {
            setProfile(true);
        } else {
            setProfile(false);
        }
        if (pathname.includes('login') || pathname.includes('/signup') || pathname === '/verify' || pathname === '/forgot-password' || pathname.includes('/reset')) {
            setCurate(true);
        } else {
            setCurate(false);
        }
        if (pathname.includes('login') || pathname.includes('/signup') || pathname === '/verify' || pathname === '/forgot-password' || pathname.includes('/reset')) {
            setAuthenticatingPage(true);
            document.querySelector("html").classList.add("full");
            document.querySelector("body").classList.add("full");
            document.getElementById("root").classList.add("full");
        } else {
            setAuthenticatingPage(false);
            document.querySelector("html").classList.remove("full");
            document.querySelector("body").classList.remove("full");
            document.getElementById("root").classList.remove("full");
        }
        if(pathname === "/collaborations/untitled") {
            setIsCollaborationsUntitled(true);
        } else {
            setIsCollaborationsUntitled(true);
        }
        setArtworkId(null);
        setExhibitionId(null)
    };

    useEffect(() => {
        if(isMobileForm && isMobileDevice()) {
            document.querySelector("body").classList.add("overflow-controls--mobile-form");
        } else {
            document.querySelector("body").classList.remove("overflow-controls--mobile-form");
        }
    }, [isMobileForm])

    // useEffect(() => {
    //     if(curateHistoryProps && curateHistoryProps.match && curateHistoryProps.match.path === ("/exhibitions/:id/details")){
    //         document.querySelector("#root > div")?.style?.height = 'auto';
    //     }
    // }, [curateHistoryProps])

    const handleFooterNewsLetterClose = () => {
        setIsNewsLetterSubmited(true);
        setShowNewsLetter(false)
    }
    const handleExhibtionDetailsNewsLetterPopupClose = () => {
        // setIsNewsLetterSubmited(true);
        setShowDetailPageNewsLatter(false);
    }

    const [showDetailPageNewsLatter, setShowDetailPageNewsLatter] = useState(false)
    const regexPattern = /^\/exhibitions\/(?=.)(.*)$/;

    return (
        <>
        {isError && <Notification value={`Oops, something went wrong...`}
                                      subTitle={`${errorMessage ? errorMessage : 'Please refresh the page and try again.'}`}
                                      onClose={() => setIsError(false, '')}/>}
            {(isArtworksPage === false && ((routeName === '/') || routeName !== '/') && isHeader) && routeName !== "/dragger" &&
            <Header routeName={routeName} isLoggedIn={isLoggedIn} isHome={isHome} isProfile={isProfile}
                    mokeHeadersDelay={routeName === '/'}
                    isSearchPage={isSearchPage}
                    loginPath={loginPath}
                    isSidebar={isFlex}
                    isAuthenticationPage={isAuthenticationPage}
                    isCollaborationsUntitled={isCollaborationsUntitled}
                    />
            }
            <HeadTagsRoute routeName={routeName}/>

            {/*// :TODO please dont remove these two comments */}
            <div className={`${isFlex ? 'flex-box' : ''} ${isCurate ? '' : 'zoom'}` } >
                <RenderRoutes isLoggedIn={isLoggedIn} setExhibitionLoading={setExhibitionLoading} redirectPath={redirectPath} setCurateHistoryProps={setCurateHistoryProps}/>
                {
                    // curateHistoryProps &&
                    // <React.Fragment>
                    //     {
                    //         regexPattern.test(location.pathname)  &&
                    //         <CuratePage
                    //           routeName
                    //         //   isHidden={curateHistoryProps.match.path===("/exhibitions/:id/details")}
                    //           isHidden={false}
                    //         //   isPreview={curateHistoryProps.match.path===("/exhibitions/preview/:id")}
                    //           isPreview={false}
                    //           handleExit={checkDetailPageNewLetterInCookie}
                    //         //   {...curateHistoryProps}
                    //         />
                    //     }
                    //     {
                    //         !isLoggedIn && showDetailPageNewsLatter && location.pathname.includes("exhibitions/") &&
                    //         <NewsLetterPopup
                    //             handleClose={handleExhibtionDetailsNewsLetterPopupClose}
                    //             setCookie={()=>setIsNewsLetterSubmited(true)}
                    //             isNewsLetterSubmited ={isNewsLetterSubmited }
                    //         />
                    //     }
                    // </React.Fragment>
                }
                {/*// TODO: Removing Chat */}
                {/* {
                    profile && profile.id &&
                    <div className={`chat-sticky-wrapper ${isConversation ? 'active' : ''}`}>
                      <div className={`chats-wrapper ${isConversation ? 'active' : ''}`}>
                        <MyProvider user={{
                            agentId: null,
                            id: profile.id,
                            name: profile.name,
                            role: profile.role,
                            status: "active"
                        }}>
                          <Conversation enquireMessage={enquireMessage} currentConversation={currentConversation}
                                        setCurrentConversation={setCurrentConversation} setExhibitionId={setExhibitionId}
                                        setEnquireMessage={setEnquireMessage} setArtworkId={setArtworkId}
                                        setChat={setChat} profile={profile} userId={profile.id} nickName={'igalsales'}
                                        isConversation={isConversation}
                                    />
                        </MyProvider>
                      </div>
                    </div>
                } */}
                <div className='body-height'/>
            </div>
            {
                !isLoggedIn && showNewsLetter && location.pathname.includes("/discover") &&
                <NewsLetter
                    setCookie={()=>setIsNewsLetterSubmited(true)}
                    handleClose={handleFooterNewsLetterClose}
                    isNewsLetterSubmited ={isNewsLetterSubmited }
                />
            }
            {/* {isArtworksPage === false && (!isHome) && !routeName.includes('/collaborations') && !routeName.includes('/search') && (history.location.pathname === "/exhibitions" || !((history.location.pathname.includes("exhibitions") && !history.location.pathname.includes("details")))) && <Footer className={artworkPreviewPage ? 'footer--no-mt' : ''}/>} */}
            {isArtworksPage === false && (!isHome) && !routeName.includes('/dragger') && !routeName.includes('/search') && (location.pathname === "/exhibitions" || !((location.pathname.includes("exhibitions") && !location.pathname.includes("details")))) && <Footer className={artworkPreviewPage ? 'footer--no-mt' : ''}/>}
            <>
                {artworkId && <ArtworkDetailsContainer
                    artworkId={artworkId} exhibitionId={exhibitionId} onHide={() => {
                    setArtworkId(null)
                    setExhibitionId(null)
                }}
                    isConversation={isConversation}
                    onLeftClick={() => logger.debug('Left Arrow Clicked!')}
                    onRightClick={() => logger.debug('Right Arrow Clicked!')}/>}
            </>
            {
                isLoggedIn && !cookieAccepted &&
                <>
                  <CookiePopup
                    description='We use cookies and other tracking technologies on our Services to remember your preferences, give you a more personalised experience and to analyse and improve our Services.  We may share this information with our partners.  For more information and for more options, please see our '
                    btnLabel="Accept"
                    onButtonClick={() => setCookieAccepted(true)}
                  />
                </>
            }
        </>
    )
};

const HeadTagsRoute=({routeName})=>{
    const regex = /(exhibitions|galleries|artists|collectives)+\/.+/gm;
    return !routeName.match(regex) && <HeadTags/>
}
const Global: React.FC<ITypeGlobal> = ({
                                           isLoggedIn,
                                           setCurrentConversation,
                                           currentConversation,
                                           enquireMessage,
                                           setEnquireMessage,
                                           setChat,
                                           exhibitionsFirstLoad,
                                           updateProfileData,
                                           redirectPath,
                                           fetchSendBirdInit,
                                           profile,
                                           isConversation,
                                           vH,
                                           isFirstLoad,
                                           setVH,
                                           artworkId,
                                           setArtworkId,
                                           exhibitionId,
                                           setExhibitionId,
                                           errorMessage,
                                           setIsError,
                                           isError,
                                           isMobileForm
                                       }) => {
    useEffect(() => {
        if (profile && profile.id) {
            const {id, firstname, image, role} = profile;
            // fetchSendBirdInit({ id: id,  name: firstname, profileUrl: image && image.data && image.data.signedUrl300x600, role: role })
        }
    }, [profile.id]);

    // updateProfileData();
    useEffect(() => {
        if (isLoggedIn) {
            updateProfileData();
        }
    }, [isLoggedIn])

    useEffect(() => {
        console.log('triggered Global');
    }, [])

    return (
        <BrowserRouter>
            <Root setChat={setChat} setCurrentConversation={setCurrentConversation} enquireMessage={enquireMessage}
                  currentConversation={currentConversation} setEnquireMessage={setEnquireMessage} artworkId={artworkId}
                  profile={profile} exhibitionsFirstLoad={exhibitionsFirstLoad} redirectPath={redirectPath}
                  setVH={setVH} isLoggedIn={isLoggedIn} isConversation={isConversation} vH={vH} exhibitionId={exhibitionId}
                  isFirstLoad={isFirstLoad} setArtworkId={setArtworkId} errorMessage={errorMessage} setExhibitionId={setExhibitionId}
                  setIsError={setIsError} isError={isError} isMobileForm={isMobileForm} />
        </BrowserRouter>
    )
};

export default Global;