import React, { useState, useEffect } from "react";
import { Heading, Paragraph, Title } from "../Typography2";
import "./style.scss";
import IconBtn from "../IconBtn";
import CrossIcon from "../../assets/icons/cross.svg";
import { toInches, isMobileDevice } from '../../utils';
import { constructImageUrl, formatePrice } from '../../utils';
import Spinner from '../../components/Spinner'
import useReadMoreData from "../hooks/useReadMoreData";
import ArtworkCard from "./ArtworkCard";
import rudderstackEvents from "../../services/rudderstack-events";
import ListviewArtworkGrid from '../ListviewArtworkGrid';
import logger from '../../utils/logger';

const CurateSidebarDark = ({
  artworks,
  onClose,
  onClickArtwork,
  isActiveBox,
  setArtwork2dModal,
  setIsLoading,
  isArtwork2dModal,
  isListView,
  roomId,
  isLoadingArtworks,
  state,
  exhibitionId,
    isOldExhibition,
    handleArtworkClick,
    setIs3dViewOpen,
    exhibitionSlug,
    uuid,
    loadingScreen,
    isPreview,
    isArtistVisible
}) => {
  const {
    loadExhibitionArtists,
    loadExhibitionArticles,
    handleGalleryClick,
    getCollertals,
    loadCurator,
    getExhibitionCurator,
    getArtists,
    getGallery,
    exhibitionCurators,
    artists
} = useReadMoreData(exhibitionSlug || uuid, isPreview);

const isCurator = async () => {
  const curatr = await loadCurator();
  if(curatr) {
    logger.debug('curator => ', curatr);
  }
}

useEffect(() => {
  // if(exhibitionId){
  if(exhibitionSlug || exhibitionId){
    // loadExhibitionArticles();
    isCurator();
    loadExhibitionArtists();
  }
}, [exhibitionSlug, exhibitionId]);
// }, [exhibitionId]);

  const [viewport, setViewPort] = useState(window.innerWidth);

  const [dimensionsUnit, setDimensionsUnit] = useState("cm");

  useEffect(() => {
    window.addEventListener('resize', () => {
      setViewPort(window.innerWidth);
    })
    setIsLoading()
  }, [])
  
  const getClass = (artists) => {
    if(artists?.length === 1) {
      return 'gallery-artists-row'
    }
    else{
      return ''
    }
  }

  const dimensionsInCM = (artwork) => {
    return `${parseFloat(artwork && artwork.height).toFixed(1)} x ${parseFloat(artwork && artwork.width).toFixed(1)} x ${parseFloat(artwork && artwork.depth).toFixed(1)} cm`;
  }
  const dimensionsInIN = (artwork) => {
    return `${toInches(artwork && artwork.height)} x ${toInches(artwork && artwork.width)} x ${toInches(artwork && artwork.depth)} in`;
  }

  return (
    <aside className="curate-aside-dark">
      {isListView?'':
      <div className="curate-aside-dark-header">
        <Heading className="artworks-number" value={!isListView ? "Artworks" : `${isLoadingArtworks ? 'Loading' : artworks.length} Artworks`} />
        <img className='close' onClick={() => onClose(false, true)} src={CrossIcon} alt="cross" />
      </div>
      }
      {/* <div onClick={() => onClose(false, true)} className='close'>
            <IconBtn type="cross" secondary />
        </div> */}

      <div className="boxes-wrapper">
        {!isListView ? <div className={`boxes ${isActiveBox ? "active" : ""}`}>
          {!loadingScreen && artworks?.length > 0 &&
            artworks
              .filter((artwork) => !artwork.data.isCollateral && (isOldExhibition || parseInt(roomId) === artwork.data.exhibitionSceneId))
              .map((item, index) => {
             return (
                  <div className="border" key={index}>
                    <div
                      className="box"
                      key={item.data.id}
                      onClick={() => handleArtworkClick(item.data.id)}
                    >
                      <div className="img">
                        {item.data.type === "2d" ? (
                          <img src={constructImageUrl(item?.data?.image, "300")} alt="artwork img" />
                        ) : item.data.type === "3d" ? (
                          <img src={constructImageUrl(item?.data?.thumbnail, "300")} alt="artwork img" />
                        ) : (
                          <img src={constructImageUrl(item?.data?.videoThumbnail, "300")} alt="artwork img" />
                          )
                        }
                      </div>
                      <div className="details">
                        <Paragraph className="title artwork-title" value={item.data?.title} />
                        <Paragraph className={"description "} value={item.data?.artist?.data?.name} />
                      </div>
                    <div className="hover-on-animate"></div>
                    </div>
                  </div>
                );
              })}
        </div> :
        <div className="boxes boxes--secondary">
          {/* {(artworks.filter((artwork) => !artwork.data.isCollateral).length > 0 || isLoadingArtworks) &&
          <>
            <Heading className="artworks-title" value={"Artworks"} />
            {
              isLoadingArtworks ?
                  <Spinner />
                  :
                  <>
                    <ListviewArtworkGrid artworks={artworks.filter((artwork) => !artwork.data.isCollateral)}
                      handleArtworkClick={(val) => {
                        handleArtworkClick(val);
                      }}  
                      setIs3dViewOpen={setIs3dViewOpen}
                     />
                    {isListView && <div className="listview-divider" />}
                  </>
            }
          </>} */}
          {/* <div className={getClass(artists)}> */}
          <div className="listview-cards-wrapper">
            <>
              <div className="gallery-artist listview-gallery-artist">
                    <div className= "gallery">
                      <Heading value={state?.data?.organisation?.data?.type === "vortic" ? "Organisation" : state?.data?.organisation?.data?.type}/>
                      {state?.data?.organisation && <>{getGallery(state?.data?.organisation, state?.data?.organisation?.data?.type === "vortic", true)}</>}
                    </div>
              </div>
            </>
            {exhibitionCurators?.length > 0 &&
            <>
              <div className="listview-divider" />
              <div className="gallery-artist">
                  <div className= "gallery">
                      <Heading value={"Curator"}/>
                      {getExhibitionCurator()}
                  </div>
              </div>
            </>
            }
            {isArtistVisible && artists.length > 0 &&
              <div id="listview-artists">
                <div className="listview-divider" />
                <div className="artist listview-artists">
                  <Heading value={artists.length === 1 ? "Artist" : "Artists"}/>
                  <div className="artists">
                    {getArtists()}
                  </div>
                </div>
              </div>
            }
          </div>
      </div>
        }
      </div>
    </aside>
  );
};

export default CurateSidebarDark;
